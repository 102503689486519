import React from "react";
import "./closebox.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import biryaniMin from "../../images/biryani-min.png";

export default function CloseBox({ loader }) {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    //round 2
    const text = document.querySelectorAll(".resizeMe2 > *");
    const tl = gsap
      .timeline()
      .fromTo(
        text,
        { x: window.innerWidth + 100 },
        { x: window.innerWidth / 2, stagger: 0.3 }
      );
    ScrollTrigger.create({
      trigger: ".holdMe2",
      animation: tl,
      pin: true,
      start: "center center",
      end: "+=100 bottom",
      scrub: 1, // I like the 1 sec delay, set to true for exact anime on scroll
      markers: true,
    });
  }, []);
  return (
    <div className="paddedCell">
      <div className="holdMe2">
        <div className="box1"></div>
        {loader && (
          <div
            className="biryani-imgdiv imhgj hide-imgzoom"
            data-aos="zoom-in"
            data-aos-offset="200"
            data-aos-delay="100"
            data-aos-duration="300"
            data-aos-easing="zoom-out"
            data-aos-mirror="true"
            data-aos-once="true"
            data-aos-anchor-placement="top-bottom"
            id="hide-imgzoom"
          >
            <img
              src={biryaniMin}
              className="biryanimain-img"
              alt="love with biryani"
            />
          </div>
        )}
        <div className="resizeMe2">
          <div className="box"></div>
        </div>
        <div className="ordered-sec">
          <a
            href="https://lovewithbiryanioow.petpooja.com/"
            target="_blank"
            className="order-now"
          >
            Order Now
          </a>
        </div>
      </div>
    </div>
  );
}
