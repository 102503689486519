import React from "react";
import AOS from "aos";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Welcomesection from "../components/welcome/Welcome";
import MapsEco from "../components/maps-eco/MapsEco";
import AmPm from "../components/ap-pm/AmPm";
import Emotion from "../components/emotion/Emotion";
import PerfectForYou from "../components/perfect-for-you/PerfectForYou";
import LoveWithBiryani from "../components/love-with-biryani/LoveWithBiryani";
import Contact from "../components/contact/Contact";
import Footer from "../components/layout/footer/Footer";
import CloseBox from "../components/close-box-copy/CloseBoxCopy";
import MobileCloseBox from "../components/close-box/CloseBox";
import { isMobile } from "react-device-detect";
import LWBPRBox from "../images/lwb-box.png";
import LWBPRBox1 from "../images/lwb-box-close.png";
import { Helmet } from "react-helmet";

function Home() {
  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    AOS.init();
    AOS.refresh();
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if (!isMobile) {
          if ($(document).scrollTop() > 5392) {
            setLoader(true);
            $("#hide-and-show-img").addClass("hide-and-show-img");
            $("#hide-imgzoom").removeClass("hide-imgzoom");
            $("#hide-imgzoom").addClass("imgzoom");
            $("#wood-sec-po-drag").addClass("wood-sec-po-drag");
          } else {
            $("#hide-and-show-img").removeClass("hide-and-show-img");
            $("#hide-imgzoom").addClass("hide-imgzoom");
            $("#hide-imgzoom").removeClass("imgzoom");
            $("#wood-sec-po-drag").removeClass("wood-sec-po-drag");
            setLoader(false);
          }
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
      if (isMobile) {
        if (
          document.getElementById("wood-sec-po")&& document.getElementById("wood-sec-po").getBoundingClientRect().top <
          239
        ) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
          $("#wood-sec-po-drag").addClass("wood-sec-po-drag");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
          $("#wood-sec-po-drag").removeClass("wood-sec-po-drag");
        }
      }
      // if (isMobile) {
      //   if (window.innerWidth == 414) {
      //     if ($(document).scrollTop() > 2392) {
      //       $("#hide-and-show-img").addClass("hide-and-show-img");
      //       setLoader(true);
      //       $("#hide-imgzoom").removeClass("hide-imgzoom");
      //       $("#hide-imgzoom").addClass("imgzoom");
      //     } else {
      //       if ($(document).scrollTop() < 2175) {
      //         $("#mobilecheck").addClass("hideimgpo");
      //       } else {
      //         $("#mobilecheck").removeClass("hideimgpo");
      //       }
      //       $("#hide-and-show-img").removeClass("hide-and-show-img");
      //       $("#hide-imgzoom").addClass("hide-imgzoom");
      //       $("#hide-imgzoom").removeClass("imgzoom");
      //       setLoader(false);
      //     }
      //   }
      //   if (window.innerWidth == 375) {
      //     if ($(document).scrollTop() > 2274) {
      //       $("#hide-and-show-img").addClass("hide-and-show-img");
      //       setLoader(true);
      //       $("#hide-imgzoom").removeClass("hide-imgzoom");
      //       $("#hide-imgzoom").addClass("imgzoom");
      //     } else {
      //       if ($(document).scrollTop() < 2175) {
      //         $("#mobilecheck").addClass("hideimgpo");
      //       } else {
      //         $("#mobilecheck").removeClass("hideimgpo");
      //       }
      //       $("#hide-and-show-img").removeClass("hide-and-show-img");
      //       $("#hide-imgzoom").addClass("hide-imgzoom");
      //       $("#hide-imgzoom").removeClass("imgzoom");
      //       setLoader(false);
      //     }
      //   }
      //   if (window.innerWidth == 360) {
      //     if ($(document).scrollTop() > 2240) {
      //       $("#hide-and-show-img").addClass("hide-and-show-img");
      //       setLoader(true);
      //       $("#hide-imgzoom").removeClass("hide-imgzoom");
      //       $("#hide-imgzoom").addClass("imgzoom");
      //     } else {
      //       if ($(document).scrollTop() < 2175) {
      //         $("#mobilecheck").addClass("hideimgpo");
      //       } else {
      //         $("#mobilecheck").removeClass("hideimgpo");
      //       }
      //       $("#hide-and-show-img").removeClass("hide-and-show-img");
      //       $("#hide-imgzoom").addClass("hide-imgzoom");
      //       $("#hide-imgzoom").removeClass("imgzoom");
      //       setLoader(false);
      //     }
      //   }
      //   if (window.innerWidth == 393) {
      //     if ($(document).scrollTop() > 2425) {
      //       $("#hide-and-show-img").addClass("hide-and-show-img");
      //       setLoader(true);
      //       $("#hide-imgzoom").removeClass("hide-imgzoom");
      //       $("#hide-imgzoom").addClass("imgzoom");
      //     } else {
      //       if ($(document).scrollTop() < 2175) {
      //         $("#mobilecheck").addClass("hideimgpo");
      //       } else {
      //         $("#mobilecheck").removeClass("hideimgpo");
      //       }
      //       $("#hide-and-show-img").removeClass("hide-and-show-img");
      //       $("#hide-imgzoom").addClass("hide-imgzoom");
      //       $("#hide-imgzoom").removeClass("imgzoom");
      //       setLoader(false);
      //     }
      //   }
      //   if (window.innerWidth == 424) {
      //     if ($(document).scrollTop() > 2495) {
      //       $("#hide-and-show-img").addClass("hide-and-show-img");
      //       setLoader(true);
      //       $("#hide-imgzoom").removeClass("hide-imgzoom");
      //       $("#hide-imgzoom").addClass("imgzoom");
      //     } else {
      //       if ($(document).scrollTop() < 2175) {
      //         $("#mobilecheck").addClass("hideimgpo");
      //       } else {
      //         $("#mobilecheck").removeClass("hideimgpo");
      //       }
      //       $("#hide-and-show-img").removeClass("hide-and-show-img");
      //       $("#hide-imgzoom").addClass("hide-imgzoom");
      //       $("#hide-imgzoom").removeClass("imgzoom");
      //       setLoader(false);
      //     }
      //   }
      // }
    });
    (function () {
      window.addEventListener("scroll", function (event) {
        var depth, i, layer, layers, len, movement, topDistance, translate3d;
        topDistance = this.pageYOffset;
        layers = document.querySelectorAll("[data-type='parallax']");
        for (i = 0, len = layers.length; i < len; i++) {
          layer = layers[i];
          depth = layer.getAttribute("data-depth");
          movement = -(topDistance * depth);
          translate3d = "translate3d(0, " + movement + "px, 0)";
          layer.style["-webkit-transform"] = translate3d;
          layer.style["-moz-transform"] = translate3d;
          layer.style["-ms-transform"] = translate3d;
          layer.style["-o-transform"] = translate3d;
          layer.style.transform = translate3d;
        }
      });
    }.call(this));
  }, []);
  return (
    <div>
      <Helmet>
        <meta
          content="Order Biryani Online in Hyderabad | Love with Biryani"
          property="og:title"
        />
        <meta
          name="description"
          content="Order Biryani Online from Love with Biryani. Chicken Biryani, Mutton Biryani, Veg Biryani , Egg Biryani & more. Indulge Into the Deliciousness of Authentic Biryani. Serving all over Hyderabad. Order Now"
        />
        <meta
          name="keywords"
          content="Love with Biryani, Biryani Online hyderabad,  Biryani Home Delivery, Biryani takeaway"
        />
        <link rel="canonical" href={window.location.href} />
        <title>Order Biryani Online in Hyderabad | Love with Biryani</title>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NQZ3WNN");
          `,
          }}
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10821370643"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-10821370643");
      `,
          }}
        ></script>
      </Helmet>
      <div id="hero">
        <Header />
        <div
          className="layer-bg layer"
          data-depth="0.10"
          data-type="parallax"
        ></div>
        <div
          className="layer-1 layer"
          data-depth="0.20"
          data-type="parallax"
        ></div>
        <div
          className="layer-2 layer"
          data-depth="0.50"
          data-type="parallax"
        ></div>
        <div
          className="layer-3 layer"
          data-depth="0.60"
          data-type="parallax"
        ></div>
        <div
          className="layer-overlay layer"
          data-depth="0.55"
          data-type="parallax"
        ></div>
        <div className="layer-4 layer" data-depth="0.30" data-type="parallax">
          <div className="aos-letters-ani">
            <div className="container">
              <div className="row">
                <div className="col">
                  <p
                    className="it1-p it1-p1"
                    data-aos="fade"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="300"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top-bottom"
                  >
                    I'm in Love
                  </p>

                  <p
                    className="it1-p it1-p2"
                    data-aos="fade"
                    data-aos-offset="200"
                    data-aos-delay="500"
                    data-aos-duration="300"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top-bottom"
                  >
                    I'm in Love
                  </p>

                  <p
                    className="it1-p it1-p3"
                    data-aos="fade"
                    data-aos-offset="200"
                    data-aos-delay="950"
                    data-aos-duration="300"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top-bottom"
                  >
                    I'm in Love
                  </p>

                  <p
                    className="it1-pa it1-p4"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="1200"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-out"
                    data-aos-mirror="true"
                    data-aos-once="false"
                    data-aos-anchor-placement="top-bottom"
                  >
                    With Biryani
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="content">
        <div className="">
          <section className="first-section">
            <Welcomesection />
            <MapsEco />
            <AmPm />
            <Emotion />
            <PerfectForYou />
            <LoveWithBiryani />
            {/* {isMobile ? (
              <MobileCloseBox loader={loader} />
            ) : (
              <CloseBox loader={loader} />
            )} */}
            <section className="wood-sec-po" id="wood-sec-po">
              <div className="container">
                <img src={LWBPRBox} />
                <img src={LWBPRBox1} id="wood-sec-po-drag" />
                <div className="text-center spmk">
                  <a
                    href="https://lovewithbiryanioow.petpooja.com/"
                    target="_blank"
                    className="order-now"
                  >
                    Order Now
                  </a>
                </div>
              </div>
            </section>
            <Contact />
            <Footer />
          </section>
        </div>
      </div>
    </div>
  );
}

export default Home;
