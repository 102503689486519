import React from "react";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";
import "../css/franchise.css";
import { Helmet } from "react-helmet";
import LocationIcon from "../images/Subtract.svg";
import ReactPlayer from "react-player";
import "../css/slider.css";
import SlickSlider from "../components/react-slick/SlickSlider";
import GenaralSlider from "../components/genaral-slider/GenaralSlider";
import GetDirection from "../images/get-direction.svg";
import Swiggy from "../images/swiggy.svg";
import Zomato from "../images/zomato.svg";
import Verticleline from "../images/verticle-line.svg";
import Whitelc from "../images/white-lc.svg";

function Franchise() {
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
    });
  }, []);

  return (
    <div className="franchise-screen">
      <Helmet>
        <meta
          content="New year Special Offer | Love with Biryani"
          property="og:title"
        />
        <meta
          name="description"
          content="Best Biryani in Hyderabad, Chicken, Mutton, Veg, Egg Biryani online in Hyderabad"
        />
        <meta
          name="keywords"
          content="Love with Biryani menu, Love with Biryani "
        />
        <link rel="canonical" href={window.location.href} />
        <title>New year Special Offer | Love with Biryani</title>
        <link rel="canonical" href={window.location.href} />
        <script type="application/ld+json">
          {`
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            name: "Love with Biryani | Pragathi Nagar",
            description:
              "Love with Biryani | Pragathi Nagar A celebration of indulgence, love, and nothing but the best. Love with Biryani introduces to you your favorite dish with a delicious twist.",
            logo: "https://lovewithbiryani.com/static/media/lwblogo.913237b5.png",
            url: "https://lovewithbiryani.com/pragathinagar",
            telephone: " +917981020407",
            sameAs: [
              "https://www.facebook.com/Lovewithbiryani",
              "https://www.youtube.com/channel/UCL_M21CMmBaNXTXqyaCgFLw/about",
              "https://www.instagram.com/lovewith_biryani/",
            ],
            address: {
              "@type": "PostalAddress",
              streetAddress:
                "Plot No 1814 to 1819, Pragathi Nagar Rd, adjacent to Godavari Cuts",
              addressLocality: "Hyderabad,",
              postalCode: " 500090",
              addressCountry: "India ",
            },
          `}
        </script>
      </Helmet>
      <Header />
      <section class="franchise-sec1 extra">
        <div className="cotainer">
          <div className="row">
            <div className="col-10 col-xs-11 col-md-10 col-lg-9 m-auto">
              <div className="location-sec">
                <img src={LocationIcon} alt="" />
                <div className="madhapur">Pragati nagar</div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10 col-lg-9 col-10 m-auto">
              <div className="box-best">
                <div className="row">
                  {/* <div className="col-4">
                    <div className="bgoip"></div>
                  </div> */}
                  <div className="col-12 text-center">
                    <h1 className="best-biryani">
                      Best Biryani in Pragati nagar
                    </h1>
                    <p className="pb-graphic">
                      The tastiest Biryani in Pragati Nagar? Look no beyond than
                      Love With Biryani. Created to satiate and satisfy those
                      who love authentic Biryani, our location offers 60+
                      varieties of the Hyderabadi delicacy and has become a
                      landmark in the area. There’s nothing you won’t like here
                      — from our authentic taste to our major portions — there’s
                      a lot to love and celebrate about.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-12">
                  <SlickSlider />
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <ReactPlayer
                    controls
                    className="react-player1"
                    url="https://www.youtube.com/embed/kAsGOBWXVbA?controls=0"
                    width="100%"
                  />
                </div>
              </div>
              <div className="sec-kjl">
                <div className="row">
                  <div className="col-md-6 col-lg-6 col-12">
                    <div className="location-details">
                      <h2 className="wel-madhapur">Welcome to Pragati nagar</h2>
                      <p className="timing-sd">
                        Timings: Morning 09:00AM - 12:00PM
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-6 col-12">
                    <div className="contact-ushd">Contact Us : 79810 20407</div>
                  </div>
                </div>
              </div>
              <div className="genaral-slider">
                <GenaralSlider />
              </div>
              <div className="latesthead">Latest Offers</div>
              <div className="row">
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="offercards">
                    <div className="offer-texsd">5% OFF</div>
                    <p className="offertexd">
                      CODE : <b>LWBFIRST</b>
                    </p>
                    <p className="offer_text">
                      <ul className="offertext_margin">
                        <li>Max discount amount 75.00</li>
                        <li>Applicable on all Items </li>
                      </ul>
                    </p>
                    <a
                      href="https://lovewithbiryanioow.petpooja.com/"
                      target="_blank"
                    >
                      <div className="ordernow-pl">Order Now</div>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="offercards">
                    <div className="offer-texsd">10% OFF</div>
                    <p className="offertexd">
                      CODE : <b>LWBFLAT10</b>
                    </p>
                    <p className="offer_text">
                      <ul>
                        <li>Min order amount 600.00</li>
                        <li>Applicable on all Items </li>
                      </ul>
                    </p>
                    <a
                      href="https://lovewithbiryanioow.petpooja.com/"
                      target="_blank"
                    >
                      <div className="ordernow-pl">Order Now</div>
                    </a>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="offercards">
                    <div className="offer-texsd">15% OFF</div>
                    <p className="offertexd">
                      CODE : <b>LWBFLAT15</b>
                    </p>
                    <p className="offer_text">
                      <ul>
                        <li>Min order amount 1000.00</li>
                        <li>Applicable on all Items </li>
                      </ul>
                    </p>
                    <a
                      href="https://lovewithbiryanioow.petpooja.com/"
                      target="_blank"
                    >
                      <div className="ordernow-pl">Order Now</div>
                    </a>
                  </div>
                </div>
              </div>
              <div className="box-best">
                <div className="row">
                  <div className="col-md-6 col-lg-5 col-12">
                    <div className="latesthead ourhshs">Our Store Address</div>
                    <p className="addresstext">
                      Plot No 1814 to 1819, Pragathi Nagar Rd, adjacent to
                      Godavari Cuts, Kakatiya Hills, KPHB, Junction, Hyderabad,
                      Telangana 500090
                    </p>
                    <div className="btngetdir">
                      <a
                        className="grt-direction-btndd"
                        href="https://goo.gl/maps/iUZKhRybQfdQNdfcA"
                        target="_blank"
                      >
                        <img src={GetDirection} width={16} />
                        &nbsp; &nbsp;Get Directions
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-7 col-12">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15218.494676083368!2d78.394445!3d17.5254691!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xf3bcae93bfb07d54!2sLove%20with%20Biryani%20%7C%20Pragathi%20Nagar!5e0!3m2!1sen!2sin!4v1644918817059!5m2!1sen!2sin"
                      height="350"
                      frameBorder="0"
                      style={{ border: "0px solid", width: "100%" }}
                      allowFullScreen=""
                    ></iframe>
                  </div>
                </div>
              </div>
              <div className="genaral-slider mb-5">
                <div className="latesthead text-center">Order online on</div>
                <div className="swiggyzomato">
                  <a
                    href="https://www.swiggy.com/restaurants/love-with-biryani-madhapur-hyderabad-449093"
                    target="_blank"
                  >
                    <img src={Swiggy} alt="" className="swiggyzomato1" />
                  </a>
                  <img src={Verticleline} alt="" className="swiggyzomato2" />
                  <a
                    href="https://www.zomato.com/hyderabad/love-with-biryani-madhapur/order"
                    target="_blank"
                  >
                    <img src={Zomato} alt="" className="swiggyzomato1" />
                  </a>
                </div>
              </div>
              <div className="box-best">
                <div className="latesthead text-center mb-5">
                  Our other locations
                </div>
                <div className="row">
                  <div className="col-6 text-center">
                    <div className="mnsmmadhapur">
                      <a
                        href="https://goo.gl/maps/KNXkqUCZ9q8et4597"
                        target="_blank"
                      >
                        <img src={Whitelc} alt="" />
                      </a>
                      Madhapur
                    </div>
                  </div>
                  <div className="col-6 text-center">
                    <div className="mnsmmadhapur">
                      <a
                        href="https://goo.gl/maps/iUZKhRybQfdQNdfcA"
                        target="_blank"
                      >
                        <img src={Whitelc} alt="" />
                      </a>
                      Pragati nagar
                    </div>
                  </div>
                  {/* <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="mnsmmadhapur">
                      <img src={Whitelc} alt="" />
                      Pragati nagar
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Franchise;
