import React from "react";
import Slider from "react-slick";
import Bigbiryani1 from "../../images/bigbiryani1.jpg";
import Bigbiryani2 from "../../images/bigbiryani2.jpg";
import Bigbiryani3 from "../../images/bigbiryani3.jpg";
import Bigbiryani4 from "../../images/bigbiryani4.jpg";
import Bigbiryani5 from "../../images/bigbiryani5.jpg";

const Baner = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="nopading">
      <Slider {...settings}>
        <img
          src={Bigbiryani1}
          alt=""
          className="w-100"
          className="slider_height"
        />
        <img
          src={Bigbiryani2}
          alt=""
          className="w-100"
          className="slider_height"
        />
        <img
          src={Bigbiryani3}
          alt=""
          className="w-100"
          className="slider_height"
        />
        <img
          src={Bigbiryani4}
          alt=""
          className="w-100"
          className="slider_height"
        />
        <img
          src={Bigbiryani5}
          alt=""
          className="w-100"
          className="slider_height"
        />
      </Slider>
    </div>
  );
};
export default Baner;
