import React from "react";
import "./welcome.css";

export default function Welcome() {
  return (
    <section className="section2">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="desktop">
              <p
                className="it2-p1"
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top-bottom"
              >
                Welcome to the land of celebrations. The land of love. The land
                of surprises. The land of joy. With every stride you make in the
                right direction, a celebration is called for. Achievements, big
                or small, must be celebrated. Successes, big or small, must be
                celebrated.
              </p>
            </div>
            <div className="mobile">
              <p className="it2-p1">
                Welcome to the land of celebrations. The land of love. The land
                of surprises. The land of joy. With every stride you make in the
                right direction, a celebration is called for. Achievements, big
                or small, must be celebrated. Successes, big or small, must be
                celebrated.
              </p>
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div className="desktop">
              <p
                className="it2-p1"
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top-bottom"
              >
                Love, in all shapes and forms, must be celebrated. Love With
                Biryani takes that emotion of celebration and elevates it to a
                culinary high. Redefining the art of gifting and celebrating,
                every morsel of our carefully crafted edible gifts is going to
                make you fall in… Love With Biryani!
              </p>
            </div>
            <div className="mobile">
              <p className="it2-p1">
                Love, in all shapes and forms, must be celebrated. Love With
                Biryani takes that emotion of celebration and elevates it to a
                culinary high. Redefining the art of gifting and celebrating,
                every morsel of our carefully crafted edible gifts is going to
                make you fall in… Love With Biryani!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
