import React from "react";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";
import "../css/franchise.css";
import ReCAPTCHA from "react-google-recaptcha";
import { _saveNewyearOrder } from "../services/Services";
import { validateFormFields } from "../utils/Index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "../components/date-picker/Datepicker";
import ScussPopup from "../components/pop-up/Scusspopup";
import { Helmet } from "react-helmet";
import Banner from "../images/Love-With-Biryani-New-year-Banner.png";
import GetGooglePlaces from "../components/google-places/GooglePlaces";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { _getAddress, _getCity } from "../services/Services";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Franchise() {
  const [isRobort, setIsRobort] = React.useState(false);
  const [values, setValues] = React.useState({
    errorMessages: {},
    email: "",
    name: "",
    mobile: "",
    location: "",
    store_open_date: new Date(),
    quantity: "",
    franchise_owner: "Veg",
    isValidForm: false,
  });
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [checkCity, setCheckCity] = React.useState("");
  const onChange = (value) => {
    if (value) {
      setIsRobort(true);
    } else {
      setIsRobort(false);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [errorMM, setErrorMM] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onsubmit = (e) => {
    e.preventDefault();
    if (values.isValidForm) {
      let mobile = !isNaN(values.mobile) ? values.mobile : "";
      let name = isNaN(values.name) ? values.name : "";
      if (mobile !== "" && values.mobile.length === 10 && name !== "" && values.location !== "" && checkCity == "Hyderabad") {
        var payload = {
          name: values.name,
          email: values.email,
          phone: values.mobile,
          location: values.location,
          date: values.store_open_date,
          selected_biryani: values.franchise_owner,
          quantity: values.quantity,
        };
        _saveNewyearOrder(payload)
          .then((res) => {
            if (res.result.status) {
              setIsSubmit(true);
              setValues({
                errorMessages: {},
                email: "",
                name: "",
                mobile: "",
                location: "",
                store_open_date: new Date(),
                monthly_salary_income: "",
                franchise_owner: "Veg",
                isValidForm: false,
                quantity: ""
              });
            } else {
              handleClick();
              setErrorMM(res.result.message);
            }
          })
          .catch((err) => console.log(err));
      }else{
        for (var prop in values) {
          var event = {
            target: {
              value: values[prop],
              name: prop,
            },
          };
          validateFormFields(event, setValues, values);
        }
      }
    } else {
      for (var prop in values) {
        var event = {
          target: {
            value: values[prop],
            name: prop,
          },
        };
        validateFormFields(event, setValues, values);
      }
    }
  };
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
    });
  }, []);

  const calldetectlocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position) => {
    var lat = position.coords.latitude;
    var lng = position.coords.longitude;
    displayLocation(lat, lng);
  };

  const displayLocation = (latitude, longitude) => {
    _getAddress(latitude, longitude)
      .then((res) => {
        console.log(res)
        setValues({
          ...values,
          location: res.data,
        });
        validateAddressFields({lat:latitude, lng:longitude})
      })
      .catch((err) => console.log(err));
  };

  const validateAddressFields = (values) => {
    if(values){
      _getCity(values.lat, values.lng)
      .then((res) => {
        setCheckCity(res.data)
      })
      .catch((err) => console.log(err));
    }else{
      setCheckCity("")
    }
  };

  return (
    <div className="franchise-screen">
      <Helmet>
        <meta
          content="New year Special Offer | Love with Biryani"
          property="og:title"
        />
        <meta
          name="description"
          content="Best Biryani in Hyderabad, Chicken, Mutton, Veg, Egg Biryani online in Hyderabad"
        />
        <meta
          name="keywords"
          content="Love with Biryani menu, Love with Biryani "
        />
        <link rel="canonical" href={window.location.href} />
        <title>New year Special Offer | Love with Biryani</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMM}
        </Alert>
      </Snackbar>
      <section class="franchise-sec">
        <div class="container">
          {/* <p className="it1-pa it1-p4 get-frch"></p> */}
          <div className="row pb-3">
            <div className="col-md-6 text-center m-auto">
              <img src={Banner} alt="" className="w-100" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 m-auto">
              <div class="franchise-form-div">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  className="w-100"
                >
                  <div className="mt-2">
                    <TextField
                      label="Name *"
                      variant="standard"
                      className="w-100"
                      name="name"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.name}
                    />
                    <div className="error-msg">{values.errorMessages.name}</div>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Email Address*"
                      variant="standard"
                      className="w-100"
                      name="email"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.email}
                    />
                    <div className="error-msg">
                      {values.errorMessages.email}
                    </div>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Mobile Number*"
                      variant="standard"
                      className="w-100"
                      name="mobile"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.mobile}
                    />
                    <div className="error-msg">
                      {values.errorMessages.mobile}
                    </div>
                  </div>
                  <div className="mt-4">
                    {/* <TextField
                      label="Where are you from"
                      variant="standard"
                      className="w-100"
                      name="location"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.location}
                    /> */}
                    <label className="pb-2">Search Your Delivery Location ( Only in Hyderabad )</label>
                    <div className="row">
                      <div className="col-10 plok-po">
                        <GetGooglePlaces
                          values={values}
                          setValues={setValues}
                          validateAddressFields={validateAddressFields}
                        />
                      </div>
                      <div className="col-2 p-0">
                        <div
                          className="location-icon-o text-center"
                          onClick={calldetectlocation}
                        >
                          <MyLocationIcon />
                        </div>
                      </div>
                    </div>
                    <div className="error-msg">
                    {checkCity == "Hyderabad"?"":values.errorMessages.location !== ''?"Please select valid Hyderabad Address":"Please select valid Hyderabad Address"}
                    </div>
                  </div>
                  <div className="mt-4">
                    <FormControl component="fieldset">
                      <label component="legend">Select Premium Biryani Gift packs</label>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="franchise_owner"
                        value={values.franchise_owner}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Veg"
                          control={<Radio />}
                          label="Veg"
                        />
                        <FormControlLabel
                          value="Chicken"
                          control={<Radio />}
                          label="Chicken"
                        />
                        <FormControlLabel
                          value="Mutton"
                          control={<Radio />}
                          label="Mutton"
                        />
                        <FormControlLabel
                          value="Fish"
                          control={<Radio />}
                          label="Fish"
                        />
                        <FormControlLabel
                          value="Prawn"
                          control={<Radio />}
                          label="Prawn"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="mt-1">
                    <TextField
                      label="Qty in number *"
                      variant="standard"
                      className="w-100"
                      name="quantity"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.quantity}
                    />
                    <div className="error-msg">
                      {values.errorMessages.quantity}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label>Date & Time of Delivery</label>
                    <DatePicker
                      values={values}
                      setValues={(payload) => setValues(payload)}
                    />
                  </div>
                  <div className="row">
                    <div className="col pt-4 pb-4">
                      <ReCAPTCHA
                        sitekey="6LfkbIAdAAAAALQlT1OypoQ6dLhQ3fW7l_jzop4t"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="text-center m-2 mb-3 pb-4">
                    <button
                      className={isRobort ? "order-now" : "order-now gtb-dis"}
                      type="submit"
                      onClick={
                        isRobort
                          ? onsubmit
                          : (e) => {
                              e.preventDefault();
                              console.log("null");
                            }
                      }
                    >
                      Submit
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScussPopup
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        hideOld={true}
      />
      <Footer />
    </div>
  );
}

export default Franchise;
