import React from "react";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";
import "../css/franchise.css";
import ReCAPTCHA from "react-google-recaptcha";
import { _saveFranchise } from "../services/Services";
import { validateFormFields } from "../utils/Index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "../components/date-picker/Datepicker";
import ScussPopup from "../components/pop-up/Scusspopup";
import { Helmet } from "react-helmet";
import { RECAPCHA } from "../constants/index";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Franchise() {
  const [isRobort, setIsRobort] = React.useState(false);
  const [values, setValues] = React.useState({
    errorMessages: {},
    email: "",
    name: "",
    mobile: "",
    location: "",
    store_open_date: new Date(),
    monthly_salary_income: "",
    other_monthly_income: "",
    franchise_owner: "Yes",
    name_of_franchise: "",
    management_experience: "",
    franchise_it: "",
    isValidForm: false,
    source_of_capital:"",
    funds_available:""
  });
  const [isSubmit, setIsSubmit] = React.useState(false);
  const onChange = (value) => {
    if (value) {
      setIsRobort(true);
    } else {
      setIsRobort(false);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [errorMM, setErrorMM] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onsubmit = (e) => {
    e.preventDefault();
    if (values.isValidForm) {
      let mobile = !isNaN(values.mobile) ? values.mobile : "";
      let name = isNaN(values.name) ? values.name : "";
      if (mobile !== "" && values.mobile.length === 10 && name !== "" && values.location !== "") {
        var payload = {
          first_name: values.name,
          last_name: "",
          email: values.email,
          phone: values.mobile,
          intrested_location: values.location,
          store_open_date: values.store_open_date,
          funds_available: values.funds_available,
          source_of_capital: values.source_of_capital,
          monthly_salary_income: values.monthly_salary_income,
          other_monthly_income: values.other_monthly_income,
          franchise_owner: values.franchise_owner,
          name_of_franchise: values.name_of_franchise,
          management_experience: values.management_experience,
          franchise_it: values.franchise_it,
        };
        console.log(payload, "okh");
        _saveFranchise(payload)
          .then((res) => {
            if (res.result.status) {
              setIsSubmit(true);
              setValues({
                errorMessages: {},
                email: "",
                name: "",
                mobile: "",
                location: "",
                store_open_date: new Date(),
                monthly_salary_income: "",
                other_monthly_income: "",
                franchise_owner: "Yes",
                name_of_franchise: "",
                management_experience: "",
                franchise_it: "",
                isValidForm: false,
                source_of_capital:"",
                funds_available:""
              });
            } else {
              handleClick();
              setErrorMM(res.result.message);
            }
          })
          .catch((err) => console.log(err));
      }else{
        for (var prop in values) {
          var event = {
            target: {
              value: values[prop],
              name: prop,
            },
          };
          validateFormFields(event, setValues, values);
        }
      }
    } else {
      for (var prop in values) {
        var event = {
          target: {
            value: values[prop],
            name: prop,
          },
        };
        validateFormFields(event, setValues, values);
      }
    }
  };
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
    });
  }, []);
  return (
    <div className="franchise-screen">
      <Helmet>
        <meta
          content="Explore Our Menu | Love with Biryani "
          property="og:title"
        />
        <meta
          name="description"
          content="Best Biryani in Hyderabad, Chicken, Mutton, Veg, Egg Biryani online in Hyderabad"
        />
        <meta
          name="keywords"
          content="Love with Biryani menu, Love with Biryani "
        />
        <link rel="canonical" href={window.location.href} />
        <title>Explore Our Menu | Love with Biryani </title>
        <link rel="canonical" href={window.location.href} />
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NQZ3WNN");
          `,
          }}
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10821370643"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-10821370643");
      `,
          }}
        ></script>
      </Helmet>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMM}
        </Alert>
      </Snackbar>
      <section class="franchise-sec">
        <div class="container">
        <p className="it1-pa it1-p4 get-frch">Get a Franchise</p>
          <div class="row">
            <div class="col-md-10 m-auto">
              <div class="franchise-form-div">
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  className="w-100"
                >
                  <div className="mt-2">
                    <TextField
                      label="Name *"
                      variant="standard"
                      className="w-100"
                      name="name"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.name}
                    />
                    <div className="error-msg">{values.errorMessages.name}</div>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Email Address*"
                      variant="standard"
                      className="w-100"
                      name="email"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.email}
                    />
                    <div className="error-msg">
                      {values.errorMessages.email}
                    </div>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Phone Number*"
                      variant="standard"
                      className="w-100"
                      name="mobile"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.mobile}
                    />
                    <div className="error-msg">
                      {values.errorMessages.mobile}
                    </div>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Intrested Location"
                      variant="standard"
                      className="w-100"
                      name="location"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.location}
                    />
                    {/* <div className="error-msg">
                      {values.errorMessages.location}
                    </div> */}
                  </div>
                  <div className="mt-4">
                    <label>Desired Store Open Date</label>
                    <DatePicker
                      values={values}
                      setValues={(payload) => setValues(payload)}
                    />
                    {/* <TextField
                      label="Desired Store  Open Date"
                      variant="standard"
                      className="w-100"
                      name="store_open_date"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.store_open_date}
                    />
                    <div className="error-msg">
                      {values.errorMessages.store_open_date}
                    </div> */}
                  </div>
                  <div className="mt-4">
                    <FormControl variant="standard" className="w-100">
                      <InputLabel id="demo-simple-select-standard-label">
                        Funds available to own a Franchise
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={values.funds_available}
                        name="funds_available"
                        label="funds_available"
                      >
                        <MenuItem value="50 lakhs - 1 cr"  onClick={() =>
                            setValues({
                              ...values,
                              funds_available: "50 lakhs - 1 cr",
                            })
                          }>
                          50 lakhs - 1 cr
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            setValues({
                              ...values,
                              funds_available: "1 cr - 2 cr",
                            })
                          }
                          value="1 cr - 2 cr"
                        >
                          1 cr - 2 cr
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            setValues({ ...values, funds_available: "3 cr" })
                          }
                          value="3 cr"
                        >
                          3 cr
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            setValues({ ...values, funds_available: "5 cr" })
                          }
                          value="5 cr"
                        >
                          5 cr
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Source of the capital"
                      variant="standard"
                      className="w-100"
                      name="source_of_capital"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      value={values.source_of_capital}
                    />
                    {/* <div className="error-msg">
                      {values.errorMessages.source_of_capital}
                    </div> */}
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Monthly Salary Income*"
                      variant="standard"
                      className="w-100"
                      name="monthly_salary_income"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.monthly_salary_income}
                    />
                    <div className="error-msg">
                      {values.errorMessages.monthly_salary_income}
                    </div>
                  </div>
                  <div className="mt-4">
                    <TextField
                      label="Other monthly Income"
                      variant="standard"
                      className="w-100"
                      name="other_monthly_income"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      value={values.other_monthly_income}
                    />
                    {/* <div className="error-msg">
                      {values.errorMessages.other_monthly_income}
                    </div> */}
                  </div>
                  <div className="mt-4">
                    <FormControl component="fieldset">
                      <label component="legend">
                        Are you Currently or previously a Franchise Owner?*{" "}
                      </label>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="franchise_owner"
                        value={values.franchise_owner}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="No"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="mt-4">
                    <label>
                      If Yes, What is the name of the franchise(s) ?
                    </label>
                    <TextareaAutosize
                      variant="standard"
                      className="w-100"
                      name="name_of_franchise"
                      onChange={(e) => {
                        setValues({
                          ...values,
                          [e.target.name]: e.target.value,
                        });
                      }}
                      value={values.name_of_franchise}
                    />
                    {/* <div className="error-msg">
                      {values.errorMessages.name_of_franchise}
                    </div> */}
                  </div>
                  <div className="mt-4">
                    <label>
                      Describe your management experience in the Food /Beverage
                      industry *
                    </label>
                    <TextareaAutosize
                      variant="standard"
                      className="w-100"
                      name="management_experience"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.management_experience}
                    />
                    <div className="error-msg">
                      {values.errorMessages.management_experience}
                    </div>
                  </div>
                  <div className="mt-4">
                    <label>
                      How did you here about Love with biryani & why do you want
                      to franchise it?*
                    </label>
                    <TextareaAutosize
                      variant="standard"
                      className="w-100"
                      name="franchise_it"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.franchise_it}
                    />
                    <div className="error-msg">
                      {values.errorMessages.franchise_it}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col pt-4 pb-4">
                      <ReCAPTCHA
                        sitekey="6LdJ36AdAAAAAH8DNCT85kpF5wUrC7wi5F2NIWHJ"
                        onChange={onChange}
                      />
                    </div>
                    <div className="col"></div>
                  </div>
                  <div className="text-center m-2 mb-3 pb-4">
                    <button
                      className={isRobort ? "order-now" : "order-now gtb-dis"}
                      type="submit"
                      onClick={
                        isRobort
                          ? onsubmit
                          : (e) => {
                              e.preventDefault();
                              console.log("null");
                            }
                      }
                    >
                      Submit
                    </button>
                  </div>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ScussPopup isSubmit={isSubmit} setIsSubmit={setIsSubmit} />
      <Footer />
    </div>
  );
}

export default Franchise;
