import React from "react";
import lwbwebsite1 from "../../videos/lwb-website-1.gif";
import lwbwebsiteMp4 from "../../videos/lwb-website-1.mp4";
import lwbwebsiteWebm from "../../videos/lwb-website-1.webm";
import lwbwebsiteOgv from "../../videos/lwb-website-1.ogv";
import "./prefectforyou.css";

export default function PerfectForYou() {
  return (
    <section>
      <div className="dis-okj">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-auto">
              <h2 className="it4-h2">
                <span className="it4-h2sp2">Perfect for</span>
              </h2>
              <h3 className="it4-h3">
                <span className="it4-h2sp2">
                  (buddies hanging out / girlfriends chilling out / families
                  shaking out)
                </span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <img src={lwbwebsite1} alt="" className="mr-btm w-100" />
      {/* <video
        poster={lwbwebsite1}
        autoPlay
        loop
        muted
        playsInline
        className="mr-btm w-100"
      >
        <source src={lwbwebsiteMp4} type="video/mp4" id="lwbVideo1" />
        <source src={lwbwebsiteWebm} type="video/webm" id="lwbVideo2" />
        <source src={lwbwebsiteOgv} type="video/ogg" id="lwbVideo3" />
        Your browser does not support HTML video.
      </video> */}
    </section>
  );
}
