import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Hamberger from "../../images/hamberger.svg";
import CloseIcon from "../../images/close-icon.svg";
import lwblogo from "../../images/lwblogo.png";
import { Link } from "react-router-dom";
import GetDirection from "../../images/get-direction.svg";
import CallIcon from "@mui/icons-material/Call";

import "./drawer.css";
export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : "100vw" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="menu-listbox">
        <ListItem button>
          <Link to="/" className="m-auto">
            <img
              src={lwblogo}
              alt="love with biryani"
              className="logoimg-po m-auto"
            />
          </Link>
        </ListItem>
        {[
          {
            name: "ABOUT US",
            link: "/aboutus",
          },
          {
            name: "MENU",
            link: "/menu",
          },
          // {
          //   name: "GET FREE BIRYANI",
          //   link: "/orders",
          // },
          // {
          //   name: "MEDIA",
          //   link: "/",
          // },
          {
            name: "FRANCHISE",
            link: "/franchise",
          },
          {
            name: "BIRYANI ANTHEM",
            link: "/biryani-anthem",
          },
          {
            name: "OFFERS",
            link: "/newyear-offer",
          },
          {
            name: "GIFTING",
            link: "/gifting",
          },
        ].map((text, index) => (
          <ListItem button key={index}>
            <div className="m-auto">
              <a href={text.link}>
                <ListItemText primary={text.name} />
              </a>
            </div>
          </ListItem>
        ))}
        <ListItem button>
          <div className="m-auto mt-2 mb-3">
            <a href="https://lovewithbiryanioow.petpooja.com/" target="_blank">
              <div className="order_online">ORDER ONLINE</div>
            </a>
          </div>
        </ListItem>
        <div className="hr_pl_pr">
          <div className="hr_hr"></div>
          </div>
        <ListItem button>
          <div className="m-auto mt-2 mb-3 address_pol">
            1st Floor, Plot no 816, 100 feet road, Ayyapa society Madhapur, next
            to YSR statue, Hyderabad, Telangana 500081
          </div>
        </ListItem>
        <div className="mt-2">
          <div className="row">
            <div className="col text-right">
              <a
                className="grt-direction-btn efdfd"
                href="https://maps.app.goo.gl/D1ww2MavCV4PT5qA9"
                target="_blank"
              >
                <img src={GetDirection} width={16} />
                &nbsp; &nbsp;Get Directions
              </a>
            </div>
            <div className="col pt-ws">
              <a href="tel:+91 79810 20407" target="_blank">
                <div className="ph-ik">
                  <CallIcon /> 79810 20407
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* <ListItem button>
          <div className="m-auto mt-2 mb-3">
            <a
              className="grt-direction-btn efdfd"
              href="https://maps.app.goo.gl/D1ww2MavCV4PT5qA9"
              target="_blank"
            >
              <img src={GetDirection} width={16} />
              &nbsp; &nbsp;Get Directions
            </a>
          </div>
        </ListItem>
        <ListItem button>
          <div className="m-auto mt-1">
            <a href="tel:+91 79810 20407" target="_blank">
              <div className="ph-ik"><CallIcon /> 79810 20407</div>
            </a>
          </div>
        </ListItem> */}
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <img src={Hamberger} className="lwblogo" alt="love with biryani" />
          </Button>
          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer(anchor, false)}
            className="drawermenu-box"
          >
            {list(anchor)}
            <div
              className="close-icon-box"
              onClick={toggleDrawer(anchor, false)}
            >
              <img src={CloseIcon} alt="love with biryani" />
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
