import React from "react";
import "./footer.css";
import lwblogo from "../../../images/lwblogo.png";
import { Link } from "react-router-dom";
import Vector2 from "../../../images/insta.svg";
import InstagramIcon from '@mui/icons-material/Instagram';

export default function Footer() {
  return (
    <section className="footer-sec">
      <div className="container">
        <div className="desktop">
          <div className="row">
            <div className="col text-center">
              <Link to="/aboutus">
                <div className="nav-link-footer">ABOUT</div>
              </Link>
            </div>
            <div className="col text-center">
              <Link to="/menu">
                <div className="nav-link-footer">MENU</div>
              </Link>
            </div>
            <div className="col text-center">
              <Link to="/">
                <img
                  src={lwblogo}
                  className="lwblogo-df"
                  alt="love with biryani"
                />
              </Link>
            </div>
            <div className="col text-center">
              {/* <Link to="/franchise"> */}
                <div className="nav-link-footer">
                <a
                  href="https://www.instagram.com/lovewith_biryani/"
                  target="_blank" 
                  className="lovewith_biryani"
                >
                  <InstagramIcon />
                  &nbsp; lovewith_biryani</a></div>
              {/* </Link> */}
            </div>
            <div className="col text-center">
              <a href="https://lovewithbiryanioow.petpooja.com/" target="_blank">
                <div className="nav-link-footer">ORDER ONLINE</div>
              </a>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="row">
            <div className="col text-center">
              <img
                src={lwblogo}
                className="lwblogo-df"
                alt="love with biryani"
              />
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <Link to="/">
                <div className="nav-link-footer">ABOUT</div>
              </Link>
            </div>
            <div className="col text-center">
              <Link to="/">
                <div className="nav-link-footer">MENU</div>
              </Link>
            </div>
            {/* <div className="col text-center">
              <Link to="/franchise">
                <div className="nav-link-footer">FRANCHISE</div>
              </Link>
            </div> */}
            <div className="col-4 text-center">
              <a href="https://lovewithbiryanioow.petpooja.com/" target="_blank">
                <div className="nav-link-footer">ORDER ONLINE</div>
              </a>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <hr />
            <div className="copy-right">
              © 2021. All Rights Reserved. Design by{" "}
              <a href="https://axlrdata.com/" target="_blank">Axlrdata</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
