import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "../screens/Home";
import Menu from "../screens/Menu";
import Franchise from "../screens/Franchise";
import Offers from "../screens/Offers";
import Media from "../screens/Media";
import Aboutus from "../screens/Aboutus";
import Orders from "../screens/NewYearOffers";
import Gifting from "../screens/Gifting";
import BucketOrders from "../screens/BucketBiryaniOffers";
import BiryaniAnthem from "../screens/BiryaniAnthem";
import Madhapur from "../screens/Madhapur";
import Pragatinagar from "../screens/Pragatinagar";

class AppRouter extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/menu" component={Menu} />
        <Route exact path="/franchise" component={Franchise} />
        <Route exact path="/offers" component={Offers} />
        <Route exact path="/media" component={Media} />
        <Route exact path="/aboutus" component={Aboutus} />
        <Route exact path="/christmas-offer" component={Orders} />
        <Route exact path="/newyear-offer" component={Orders} />
        <Route exact path="/gifting" component={Gifting} />
        <Route exact path="/bucket-biryani-offer" component={BucketOrders} />
        <Route exact path="/biryani-anthem" component={BiryaniAnthem} />
        <Route exact path="/madhapur" component={Madhapur} />
        <Route exact path="/pragatinagar" component={Pragatinagar} />
        <Router />
      </Switch>
    );
  }
}

export default AppRouter;
