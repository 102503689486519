import React from "react";
import "./contact.css";
import lbwWeb2 from "../../videos/lwb-website-2.jpg";
import lbwWeb2Mp4 from "../../videos/lwb-website-2.gif";
import lbwWeb2Webm from "../../videos/lwb-website-2.webm";
import lbwWeb2Ogv from "../../videos/lwb-website-2.ogv";
import Vector from "../../images/googleplus.svg";
import Vector1 from "../../images/fb.svg";
import Vector2 from "../../images/insta.svg";
import Vector3 from "../../images/youtube.svg";
import GetDirection1 from "../../images/get-po.png";

export default function Contact() {
  return (
    <section className="mobile-dgfh">
      <img src={lbwWeb2Mp4} alt="" className="w-100" />
      {/* <video
        poster={lbwWeb2}
        autoPlay
        loop
        muted
        playsInline
        id="lwbVideo"
        className="w-100"
      >
        <source src={lbwWeb2Mp4} type="video/mp4" id="lwbVideo1" />
        <source src={lbwWeb2Webm} type="video/webm" id="lwbVideo2" />
        <source src={lbwWeb2Ogv} type="video/ogg" id="lwbVideo3" />
        Your browser does not support HTML video.
      </video> */}
      <div className="footer-jbv">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-auto text-center">
              <h2 className="it4-h2 contact">
                <span className="it4-h2sp2">Contact</span>
              </h2>
              <h3 className="it4-h3">
                <span className="it4-h2sp3">Address</span>
              </h3>
              <p className="itm-desc mnl">
                1st Floor, Plot no 816, 100 feet road, Ayyapa society Madhapur,
                <br />
                next to YSR statue, Hyderabad, Telangana 500081 &nbsp;<a
                  className="grt-direction-btn born"
                  href="https://maps.app.goo.gl/D1ww2MavCV4PT5qA9"
                  target="_blank"
                >
                  <img src={GetDirection1} width={16} />
                </a>
              </p>
              <a href="tel:+91 79810 20407" target="_blank">
                <p className="phone-text">+91 79810 20407</p>
              </a>
              <div className="mt-sm-4 svg-handle">
                {/* <a><img src={Vector} alt="love with biryani" className="love-mr" /></a> */}
                <a
                  href="https://www.facebook.com/Lovewithbiryani"
                  target="_blank"
                >
                  <img
                    src={Vector1}
                    alt="love with biryani"
                    className="love-mr"
                  />
                </a>
                <a
                  href="https://www.instagram.com/lovewith_biryani/"
                  target="_blank"
                >
                  <img
                    src={Vector2}
                    alt="love with biryani"
                    className="love-mr"
                  />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCL_M21CMmBaNXTXqyaCgFLw"
                  target="_blank"
                >
                  <img
                    src={Vector3}
                    alt="love with biryani"
                    className="love-mr"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
