import React from "react";
import "./lovewithbiryani.css";
import lbwWeb3 from "../../videos/lwb-website-3.jpg";
import lbwWeb3Mp4 from "../../videos/lwb-website-3.gif";
import lbwWeb3Webm from "../../videos/lwb-website-3.webm";
import lbwWeb3Ogv from "../../videos/lwb-website-3.ogv";
import AOS from "aos";
import "aos/dist/aos.css";

export default function LoveWithBiryani() {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <section>
      <div className="mr-jtm">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-auto">
              <h2 className="it4-h2">
                <span className="it4-h2sp2">Let's redefine gifting</span>
              </h2>
              <h2 className="it4-h2">
                <span className="it4-h2sp2">Let's redefine celebration</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <img src={lbwWeb3Mp4} alt="" className="w-100 mngk" />
      {/* <video
        poster={lbwWeb3}
        autoPlay
        loop
        muted
        playsInline
        id="lwbVideo"
        className="w-100"
      >
        <source src={lbwWeb3Mp4} type="video/mp4" id="lwbVideo1" />
        <source src={lbwWeb3Webm} type="video/webm" id="lwbVideo2" />
        <source src={lbwWeb3Ogv} type="video/ogg" id="lwbVideo3" />
        Your browser does not support HTML video.
      </video> */}
    </section>
  );
}
