import React from "react";
import './emotion.css';
import faces from '../../images/faces.svg';

export default function Emotion() {
  return (
    <section id="item-5" className="section5">
      <div className="container">
        <div className="row">
          <div className="col">
            <h2 className="it4-h2">
              <span className="it4-h2sp2">Biryani is not just food.</span>
            </h2>
            <h3 className="it4-h3">
              <span className="it4-h2sp2">It’s an emotion.</span>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p className="faces-imgp">
              <img
                src={faces}
                className="faces-img"
                alt="love with biryani"
                data-aos="fade-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="800"
                data-aos-offset="0"
                data-aos-mirror="true"
                data-aos-once="false"
                data-aos-anchor-placement="top-bottom"
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
