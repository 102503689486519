import React from "react";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";

function Media() {
  React.useEffect(() => {
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
    });
  }, []);
  return (
    <div>
      <Header />

      <Footer />
    </div>
  );
}

export default Media;
