import React, { useState } from "react";
import Slider from "react-slick";
import Review1 from "../../images/review1.jpeg";
import Review2 from "../../images/review2.jpeg";
import Review3 from "../../images/review3.jpeg";
import Review4 from "../../images/review4.jpeg";
import styled from "styled-components";

const ShowMoreText = styled.div`
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 15px;
  text-decoration: underline;
`;
const reviewtext1 = `There may be many biryani joints in Hyd, but if you are looking
One of its kind, you must and should try this “Love With Biryani”
and you will fall definietly fall in love. Must try biryanis
Gongura Mutton, konaseema, palnadu, ghee roast, raju gari
biryanis…Finally i would say Dont miss tasting this one of its
kind Biryanis`;
const reviewtext2 = `Palnadu biryani was delicious, it got an excellent, authentic taste of it. Also Rice cooked to perfection ( quality of Rice is a top notch) and chicken was succulent, each and every biryani posses unique flavors so they thrived to get that taste 😋 you can really enjoy it and the difference is real. Shout out to their packaging especially if you want to gift, this will mark your celebration`;
const reviewtext3 = `Fantastic taste. Excellent packaging. Fulfilling quantity. This
kitchen has winner written all over it. Ordered a feast for 15
people, it’s safe to say, the brand now has 15 new fans! 🥳🥰`;
const reviewtext4 = `I never seen this type of packing ,it's nice Taste was delicious
😋😋🤤`;

const Baner = (props) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [isShowMore1, setIsShowMore1] = useState(true);
  const toggleReadMore1 = () => {
    setIsShowMore1(!isShowMore1);
  };
  const [isShowMore2, setIsShowMore2] = useState(true);
  const toggleReadMore2 = () => {
    setIsShowMore2(!isShowMore2);
  };
  const [isShowMore3, setIsShowMore3] = useState(true);
  const toggleReadMore3 = () => {
    setIsShowMore3(!isShowMore3);
  };
  const [isShowMore4, setIsShowMore4] = useState(true);
  const toggleReadMore4 = () => {
    setIsShowMore4(!isShowMore4);
  };

  return (
    <div className="genaral-box">
      <Slider {...settings}>
        <div className="sec-slider">
          <div className={isShowMore1 ? "subsliderh1" : "subsliderh"}>
            <img src={Review1} alt="" className="subshs-img" />
            <p className="publcish">
              {isShowMore1 ? reviewtext1.slice(0, 200) : reviewtext1}
              {reviewtext1 && reviewtext1.length > 200 && (
                <ShowMoreText onClick={toggleReadMore1}>
                  {isShowMore1 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Ranganath Yarlagadda</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore2 ? "subsliderh1" : "subsliderh"}>
            <img src={Review2} alt="" className="subshs-img" />
            <p className="publcish">
              {isShowMore2 ? reviewtext2.slice(0, 200) : reviewtext2}
              {reviewtext2 && reviewtext2.length > 200 && (
                <ShowMoreText onClick={toggleReadMore2}>
                  {isShowMore2 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Chandana Chowdary Jampani</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore3 ? "subsliderh1" : "subsliderh"}>
            <img
              src={Review3}
              alt="review"
              width="115px"
              className="subshs-img"
            />
            <p className="publcish">
              {isShowMore3 ? reviewtext3.slice(0, 200) : reviewtext3}
              {reviewtext3 && reviewtext3.length > 200 && (
                <ShowMoreText onClick={toggleReadMore3}>
                  {isShowMore3 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Devesh Baheti</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore4 ? "subsliderh1" : "subsliderh"}>
            <img src={Review4} alt="" className="subshs-img" />
            <p className="publcish">
              {isShowMore4 ? reviewtext4.slice(0, 200) : reviewtext4}
              {reviewtext4 && reviewtext4.length > 200 && (
                <ShowMoreText onClick={toggleReadMore4}>
                  {isShowMore4 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Krishna Akarapu</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore1 ? "subsliderh1" : "subsliderh"}>
            <img src={Review1} alt="" className="subshs-img" />
            <p className="publcish">
              {isShowMore1 ? reviewtext1.slice(0, 200) : reviewtext1}
              {reviewtext1 && reviewtext1.length > 200 && (
                <ShowMoreText onClick={toggleReadMore1}>
                  {isShowMore1 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Ranganath Yarlagadda</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore2 ? "subsliderh1" : "subsliderh"}>
            <img src={Review2} alt="" className="subshs-img" />
            <p className="publcish">
              {isShowMore2 ? reviewtext2.slice(0, 200) : reviewtext2}
              {reviewtext2 && reviewtext2.length > 200 && (
                <ShowMoreText onClick={toggleReadMore2}>
                  {isShowMore2 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Chandana Chowdary Jampani</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore3 ? "subsliderh1" : "subsliderh"}>
            <img
              src={Review3}
              alt="review"
              width="115px"
              className="subshs-img"
            />
            <p className="publcish">
              {isShowMore3 ? reviewtext3.slice(0, 200) : reviewtext3}
              {reviewtext3 && reviewtext3.length > 200 && (
                <ShowMoreText onClick={toggleReadMore3}>
                  {isShowMore3 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Devesh Baheti</p>
          </div>
        </div>
        <div className="sec-slider">
          <div className={isShowMore4 ? "subsliderh1" : "subsliderh"}>
            <img src={Review4} alt="" className="subshs-img" />
            <p className="publcish">
              {isShowMore4 ? reviewtext4.slice(0, 200) : reviewtext4}
              {reviewtext4 && reviewtext4.length > 200 && (
                <ShowMoreText onClick={toggleReadMore4}>
                  {isShowMore4 ? "Show more..." : "Show less"}
                </ShowMoreText>
              )}
            </p>
            <p className="review_text">Krishna Akarapu</p>
          </div>
        </div>
      </Slider>
    </div>
  );
};
export default Baner;
