import HttpCalls from "./ApiCall";

export const _getCount = async () => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("GET", "claimcount", headers);
};

export const _climeBiryani = async (payload) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("POST", "claimbiryani", headers, payload);
};

export const _saveFranchise = async (payload) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("POST", "franchise", headers, payload);
};

export const _saveBulkOrder = async (payload) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("POST", "bulk-order", headers, payload);
};

export const _getLocations = async (location) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("GET", "getLocations?location_name=" + location, headers);
};

export const _getAddress = async (latitude, longitude) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call(
    "GET",
    "getAddress?latitude=" + latitude + "&longitude=" + longitude,
    headers
  );
};

export const _getCity = async (latitude, longitude) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call(
    "GET",
    "getCity?latitude=" + latitude + "&longitude=" + longitude,
    headers
  );
};


export const _saveBucketBiryani = async (payload) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("POST", "bucket-biryani-offer", headers, payload);
};

export const _saveNewyearOrder = async (payload) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("POST", "new-year-order", headers, payload);
};

export const _saveGiftingOrder = async (payload) => {
  let { _do_call } = HttpCalls;
  let headers = await headersData();
  return _do_call("POST", "gifting", headers, payload);
};

const headersData = async (cookies) => {
  return {
    headers: {
      "Content-Type": "application/json",
      sessionid: "eaifdoaenfa",
    },
  };
};

export default {
  headersData,
  _getCount,
  _climeBiryani,
  _saveFranchise,
  _saveBulkOrder,
  _getLocations,
  _getAddress,
  _saveBucketBiryani,
  _saveNewyearOrder,
  _saveGiftingOrder
};
