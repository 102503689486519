import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import biryaniMinRectangle from "../../images/Biryani-image.png";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#fff",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    position: "absolute",
    right: "20px",
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded":{
    transform: "rotate(-90deg)",
  },
  "& p": {
    color: "#585858",
    letterSpacing: "0.4px",
    fontSize: "14px",
    fontWeight: "600",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions({ json }) {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      {json.map((res, key) => (
        <Accordion
          expanded={expanded === "panel" + (key + 1)}
          onChange={handleChange("panel" + (key + 1))}
        >
          <AccordionSummary id="panel1d-header">
            <Typography>{res.contegory}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {res.products_data.map((result, index) => (
                <div className="row" key={index}>
                  <div className="col">
                    <h2 className="pro-name">{result.name}</h2>
                    {/* <p className="pro-cantent">
                      {result.discription.length > 100
                        ? result.discription.substr(0, 100) + " ..."
                        : result.discription}
                    </p> */}
                  </div>
                  {/* <div className="col-4 text-center">
                    <img
                      src={biryaniMinRectangle}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                  </div> */}
                </div>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
