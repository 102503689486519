import React from "react";
import AOS from "aos";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import { isMobile } from "react-device-detect";
import biryaniMin from "../images/biryani-min.png";
import biryaniMinRectangle from "../images/Biryani-image.png";
// import Flipbook from "../components/flipbook/Flipbook";
// import MobileFlipbook from "../components/flipbook/MobileFlipbook";
import CustomizedAccordions from "../components/accordions/Accordions";
import Footer from "../components/layout/footer/Footer";
import { Helmet } from "react-helmet";

function Home() {
  const [activeName, setActiveName] = React.useState("Veg Biryani");
  const [json, setJson] = React.useState([
    {
      id: 1,
      contegory: "Veg Biryani",
      products_data: [
        {
          id: 1,
          name: "Premium Veg Dum Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Avakai Veg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Ulavacharu Veg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "PanduMirchi Veg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Gongura Veg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 6,
          name: "Paneer Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 7,
          name: "Paneer Tikka Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 8,
          name: "Pachimirchi Paneer Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 9,
          name: "Kaju Mushroom Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 10,
          name: "Green Mirchi Mushroom Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 11,
          name: "Konaseema Paneer Biriyani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 12,
          name: "Palnadu Paneer Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 13,
          name: "Raju Gari Paneer Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 14,
          name: "Veg Kofta Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 15,
          name: "Kadai Veg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 2,
      contegory: "Chicken Biryani",
      products_data: [
        {
          id: 1,
          name: "Hyderabadi Chicken Dum Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Avakai Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Ulavacharu Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "Temper Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Gongura Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 6,
          name: "Chicken Tikka Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 7,
          name: "Boneless Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 8,
          name: "Boneless Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 9,
          name: "Vijayawada Special Boneless Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 10,
          name: "Chicken 65 Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 11,
          name: "Pachi Mirchi Kodi Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 12,
          name: "Chicken Joint Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 13,
          name: "Chicken Fry Piece Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 14,
          name: "Ghee Roast Kaju Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 15,
          name: "Raju Gari Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 16,
          name: "Konaseema Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 17,
          name: "Chicken Mughlai Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 18,
          name: "Boneless Ulavacharu Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 19,
          name: "Karivepaku Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 20,
          name: "Guntur Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 21,
          name: "Palnadu Chicken Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 3,
      contegory: "Egg Biryani",
      products_data: [
        {
          id: 1,
          name: "Egg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Ulavacharu Egg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Egg Roast Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "Green Mirchi Egg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Raju Gari Egg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 6,
          name: "Konaseema Egg Biriyani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 7,
          name: "Palnadu Egg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 4,
      contegory: "Seafood Biryani",
      products_data: [
        {
          id: 1,
          name: "Fish Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Andhra Prawns Fry Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Konaseema Prawns Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "Raju Gari Prawns Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Prawns Cashew Ghee Roast Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 6,
          name: "Pachimirchi Prawns Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 5,
      contegory: "Goat Biryani",
      products_data: [
        {
          id: 1,
          name: "Hyderabadi Goat Dum Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Avakai Goat Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Ulavacharu Goat Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "Temper Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Gongura Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 6,
          name: "Boneless Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 7,
          name: "Kheema Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 8,
          name: "Red Mirchi Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 9,
          name: "Mutton Fry Piece Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 10,
          name: "Kheema Kaju Egg Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 11,
          name: "Ghee Roast Kaju Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 12,
          name: "Raju Gari Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 13,
          name: "Konaseema Mutton Biryani",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 6,
      contegory: "Veg Kebabs",
      products_data: [
        {
          id: 1,
          name: "Paneer Tikka Kebab",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Tandoori Broccoli",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Hara Bhara kebab",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 7,
      contegory: "Chicken Kebabs",
      products_data: [
        {
          id: 1,
          name: "Murg Peshawari",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Chicken Tikka",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Tandoori Chicken",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 8,
      contegory: "Mutton Kebabs",
      products_data: [
        {
          id: 1,
          name: "Mutton Ki Sooleh",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Mutton Achari Kebab",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Special Mutton Sheekh Kebab",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        // {
        //   id:4,
        //   name:"Mutton ki sooleh",
        //   discription:"In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!"
        // }
      ],
    },
    {
      id: 9,
      contegory: "Fish Kebabs",
      products_data: [
        {
          id: 1,
          name: "Tali Hui Machili",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Malai Fish Tikka",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 10,
      contegory: "Prawns Kebabs",
      products_data: [
        {
          id: 1,
          name: "Tandoori jhinga",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 11,
      contegory: "Veg Curries",
      products_data: [
        {
          id: 1,
          name: "Paneer Tikka Masala",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Veg Melody",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 12,
      contegory: "Chicken Curries",
      products_data: [
        {
          id: 1,
          name: "Butter Chicken",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Hyderabad Chicken Curry",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Dum Ka Chicken",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "Kachi Mirchi Murgh",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Love With Biryani Special Chicken Curry",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 13,
      contegory: "Mutton Curries",
      products_data: [
        {
          id: 1,
          name: "Telangana Mamsam Kura",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Dum Ka Gosht",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Kachi Mirchi Gosht",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 14,
      contegory: "Prawns Curries",
      products_data: [
        {
          id: 1,
          name: "Kadai Prawns",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Prawn Butter Masala",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 15,
      contegory: "Fish Curries",
      products_data: [
        {
          id: 1,
          name: "Telangana Fish Curry",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Fish Chettinad",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 16,
      contegory: "Veg Starters",
      products_data: [
        {
          id: 1,
          name: "Paneer 65",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 17,
      contegory: "Chicken Starters",
      products_data: [
        {
          id: 1,
          name: "Chicken 65",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
    {
      id: 18,
      contegory: "Sides",
      products_data: [
        {
          id: 1,
          name: "Plain Naan",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 2,
          name: "Butter Naan",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 3,
          name: "Garlic Naan",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 4,
          name: "Chilli Naan",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 5,
          name: "Onion Kulcha",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 6,
          name: "Tandoori Roti",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 7,
          name: "Plain Rice",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
        {
          id: 8,
          name: "Jeera Rice",
          discription:
            "In publishing and graphic desing , Love, in all shapes and forms, must be celebrated. Love With Biryani takes that emotion of celebration and elevates it to a culinary high. Redefining the art of gifting and celebrating, every morsel of our carefully crafted edible gifts is going to make you fall in… Love With Biryani!",
        },
      ],
    },
  ]);

  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
      if (isMobile) {
        if ($(document).scrollTop() > 2340) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          if ($(document).scrollTop() < 2175) {
            $("#mobilecheck").addClass("hideimgpo");
          } else {
            $("#mobilecheck").removeClass("hideimgpo");
          }
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      }
    });
    (function () {
      window.addEventListener("scroll", function (event) {
        var depth, i, layer, layers, len, movement, topDistance, translate3d;
        topDistance = this.pageYOffset;
        layers = document.querySelectorAll("[data-type='parallax']");
        for (i = 0, len = layers.length; i < len; i++) {
          layer = layers[i];
          depth = layer.getAttribute("data-depth");
          movement = -(topDistance * depth);
          translate3d = "translate3d(0, " + movement + "px, 0)";
          layer.style["-webkit-transform"] = translate3d;
          layer.style["-moz-transform"] = translate3d;
          layer.style["-ms-transform"] = translate3d;
          layer.style["-o-transform"] = translate3d;
          layer.style.transform = translate3d;
        }
      });
    }.call(this));
  }, []);
  return (
    <div className="menu-screen">
      <Helmet>
        <meta
          content="Explore Our Menu | Love with Biryani "
          property="og:title"
        />
        <meta
          name="description"
          content="Best Biryani in Hyderabad, Chicken, Mutton, Veg, Egg Biryani online in Hyderabad"
        />
        <meta
          name="keywords"
          content="Love with Biryani menu, Love with Biryani "
        />
        <link rel="canonical" href={window.location.href} />
        <title>Explore Our Menu | Love with Biryani </title>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NQZ3WNN");
          `,
          }}
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10821370643"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-10821370643");
      `,
          }}
        ></script>
      </Helmet>
      {/* <div className="menu-top-sec">
        <div className="desktop">
          <Flipbook />
        </div>
        <div className="mobile">
          <MobileFlipbook />
        </div>
      </div> */}
      <Header />
      {!isMobile && (
        <div className="menu-top-sec">
          <p className="it1-pa it1-p4">Our Specials</p>
          {/* <div className="container">
            <div className="row">
              <div className="col-10 m-auto">
                <div className="row">
                  <div className="col text-center">
                    <img
                      src={biryaniMin}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                    <p className="sample-name">
                      Sample
                      <br />
                      Biryani Name
                    </p>
                  </div>
                  <div className="col text-center">
                    <img
                      src={biryaniMin}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                    <p className="sample-name">
                      Sample
                      <br />
                      Biryani Name
                    </p>
                  </div>
                  <div className="col text-center">
                    <img
                      src={biryaniMin}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                    <p className="sample-name">
                      Sample
                      <br />
                      Biryani Name
                    </p>
                  </div>
                  <div className="col text-center">
                    <img
                      src={biryaniMin}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                    <p className="sample-name">
                      Sample
                      <br />
                      Biryani Name
                    </p>
                  </div>
                  <div className="col text-center">
                    <img
                      src={biryaniMin}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                    <p className="sample-name">
                      Sample
                      <br />
                      Biryani Name
                    </p>
                  </div>
                  <div className="col text-center">
                    <img
                      src={biryaniMin}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                    <p className="sample-name">
                      Sample
                      <br />
                      Biryani Name
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      )}
      {!isMobile && (
        <section className="sidebar-sec">
          <div className="container">
            <div className="row">
              <div className="col-10 m-auto">
                <div className="side-card">
                  <div className="row">
                    <div className="col-3 sidebar">
                      {[
                        "Veg Biryani",
                        "Egg Biryani",
                        "Chicken Biryani",
                        "Goat Biryani",
                        "Seafood Biryani",
                        "Veg Kebabs",
                        "Chicken Kebabs",
                        "Mutton Kebabs",
                        "Fish Kebabs",
                        "Prawns Kebabs",
                        "Veg Curries",
                        "Chicken Curries",
                        "Mutton Curries",
                        "Prawns Curries",
                        "Fish Curries",
                        "Veg Starters",
                        "Chicken Starters",
                        "Sides",
                      ].map((res, key) => (
                        <div
                          className={
                            activeName !== res
                              ? "nav-item-cs"
                              : "nav-item-cs isacitvecls"
                          }
                          key={key}
                          onClick={() => setActiveName(res)}
                        >
                          {res}
                        </div>
                      ))}
                    </div>
                    <div className="col pl-zero">
                      <div className="sidebar-content pt-4">
                        {json.map(
                          (res, key) =>
                            res.contegory === activeName &&
                            res.products_data.map((result, i) => (
                              <div className="product-card" key={i}>
                                <div className="row">
                                  <div className="col">
                                    <h2 className="pro-name">{result.name}</h2>
                                    {/* <p className="pro-cantent">
                                      {result.discription}
                                    </p> */}
                                  </div>
                                  {/* <div className="col-4 text-center">
                                    <img
                                      src={biryaniMinRectangle}
                                      className="biryanimain-img"
                                      alt="love with biryani"
                                    />
                                  </div> */}
                                </div>
                                <hr />
                              </div>
                            ))
                        )}
                      </div>
                      <a
                        href="https://lovewithbiryanioow.petpooja.com/"
                        target="_blank"
                      >
                        <div className="stickyfooter-1">Order online </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </section>
      )}
      {isMobile && (
        <div className="mrtops">
          <div className="container">
            <section className="mobile-menu">
              <div className="menu-mobile-po">MENU</div>
              <CustomizedAccordions json={json} />
              {/* <a href="https://lovewithbiryanioow.petpooja.com/" target="_blank"><div className="stickyfooter-1">Order online </div></a> */}
            </section>
          </div>
        </div>
      )}
      <br />
      <Footer />
    </div>
  );
}

export default Home;
