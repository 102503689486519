import React from "react";
import "./closeboxcopy.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import biryaniMin from "../../images/biryani-min.png";

export default function CloseBoxCopy({ loader }) {
  React.useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    //round 2
    const text = document.querySelectorAll(".resizeMe2-sc > *");
    const tl = gsap
      .timeline()
      .fromTo(
        text,
        { x: window.innerWidth },
        { x: window.innerWidth / 2, stagger: 0.3 }
      );
    ScrollTrigger.create({
      trigger: ".holdMe2-sc",
      animation: tl,
      pin: true,
      start: "center center",
      end: "+=1500 bottom",
      scrub: 1, // I like the 1 sec delay, set to true for exact anime on scroll
      markers: true,
    });
  }, []);
  return (
    <div>
      <div className="paddedCell-sc">
        <div className="holdMe2-sc">
          <div className="box-sc1"></div>
          {loader && (
            <div
              className="biryani-imgdiv imhgj hide-imgzoom"
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-delay="100"
              data-aos-duration="300"
              data-aos-easing="zoom-out"
              data-aos-mirror="true"
              data-aos-once="true"
              data-aos-anchor-placement="top-bottom"
              id="hide-imgzoom"
            >
              <img
                src={biryaniMin}
                className="biryanimain-img"
                alt="love with biryani"
              />
            </div>
          )}
          <div className="resizeMe2-sc">
            <div className="box-sc"></div>
          </div>
          <div className="ordered-sec">
            <a
              href="https://lovewithbiryanioow.petpooja.com/"
              target="_blank"
              className="order-now"
            >
              Order Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
