import React from "react";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";
import "../css/franchise.css";
import ReCAPTCHA from "react-google-recaptcha";
import {useForm} from "react-hook-form";

const errorMessage = error => {
  return <div className="invalid-feedback">{error}</div>;
};

const required = "This field is required";
const maxLength = "Your input exceed maximum length";

function Contact() {
  
  const { register, handleSubmit, errors } = useForm();
  const onSubmit = data => console.log(data);

  const onChange = (value) => {
    console.log("Captcha value:", value);
  };
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
    });
  }, []);
  return (
    <div className="offers-screen">
      <Header />
      <section class="franchise-sec">
        <div class="container">
          <div class="row">
            <div class="col-md-10 m-auto">
              <div class="franchise-form-div">
                <h3 class="franchise-form-h3">Congrats :)</h3>
                <form name="franchise-form" onSubmit={handleSubmit(onSubmit)}>
                  <h4 class="franchise-form-h4">Application Information</h4>
                  <hr />
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label class="franchise-formlbl" for="firstname">
                        Name *
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="firstname"
                        placeholder="First Name"
                        name="firstname"
                        {...register('firstname', { required: true })}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label class="franchise-formlbl" for="phone">
                        Phone Number *
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        id="phone"
                        placeholder="Phone Number"
                        name="phone"
                        {...register('phone', { required: true })}
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 form-group">
                      <label class="franchise-formlbl" for="herefranchise">
                        Address
                      </label>
                      <textarea
                        class="form-control"
                        rows="3"
                        id="herefranchise"
                        name="herefranchise"
                        {...register('herefranchise', { required: true })}
                      ></textarea>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="captcha">
                        <ReCAPTCHA
                          sitekey="6LfkbIAdAAAAALQlT1OypoQ6dLhQ3fW7l_jzop4t"
                          onChange={onChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-center m-4">
                    <button className="order-now" type="submit">Get Free Biryani</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Contact;
