import React from "react";
import { _getLocations } from "../../services/Services";
import Select from "react-dropdown-select";
import styled from "@emotion/styled";

export default function GooglePlaces({ values, setValues, validateAddressFields }) {
  const [state, setstate] = React.useState([]);

  const getPlaces = (e) => {
    setValues({
      ...values,
      location: e.target.value,
    });
    _getLocations(e.target.value)
      .then((res) => {
        setstate(res.data.results);
    validateAddressFields(false)
      })
      .catch((err) => console.log(err));
  };

  const customContentRenderer = ({ props, state }) => {
    return (
      <div style={{ width: "100%" }}>
        <input
          type="text"
          className="searchable-box"
          onChange={(e) => getPlaces(e)}
          value={
            values.location
          }
          placeholder="Search for a nearby landmark, locality"
        />
      </div>
    );
  };

  return (
    <div>
      <Select
        multi={false}
        options={state}
        itemRenderer={({ item, methods }) => (
          <StyledItem
            onClick={() => {
              methods.addItem(item);
              setValues({
                ...values,
                location: item.formatted_address,
              });
              validateAddressFields(item.geometry.location)
            }}
          >
            <div>{item.name}</div>
            <div>{item.formatted_address}</div>
          </StyledItem>
        )}
        contentRenderer={customContentRenderer}
      />
    </div>
  );
}

const StyledItem = styled.div`
  padding: 10px;
  color: #555;
  border-radius: 3px;
  margin: 3px;
  cursor: pointer;
  > div {
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 0px;
  }
  :hover {
    background: #f2f2f2;
  }
`;
