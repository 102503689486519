import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Slide from "@mui/material/Slide";
import ScussIcon from "../../images/scuss-icon.png";
import CloseIcon from "../../images/close-icon-black.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ isSubmit, setIsSubmit, hideOld }) {
  return (
    <div>
      <Dialog
        open={isSubmit}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setIsSubmit(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <div className="text-right">
            <img src={CloseIcon} alt="" width={20} onClick={() => setIsSubmit(false)} />
          </div>
          <div className="text-center mt-4">
            <img src={ScussIcon} width={50} />
            <div className="bold-msg">
              {hideOld?<div>Stay Tuned!</div>:<div>Thank you for showing <br></br>interest in us.</div>}
            </div>
            <div className="note-sdg">
              {!hideOld?<div>We will get back to you at <br></br>the earliest.</div>:<div>Our Biryani experts are about to call you for an indulging experience!</div>}
            </div>
            <br />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
