import React from "react";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import Footer from "../components/layout/footer/Footer";
import "../css/franchise.css";
import { _saveGiftingOrder } from "../services/Services";
import { validateFormFields } from "../utils/Index";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ScussPopup from "../components/pop-up/Scusspopup";
import { Helmet } from "react-helmet";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import RoundedBall from "../images/round-ball.svg";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Franchise() {
  const [isRobort, setIsRobort] = React.useState(false);
  const [values, setValues] = React.useState({
    errorMessages: {},
    email: "",
    name: "",
    mobile: "",
    country: "",
    franchise_owner: "Veg",
    quantity: "",
    giftingToname: "",
    message: "",
    giftingTofullname: "",
    giftingTomobile: "",
    giftingToemail: "",
    giftingToaddress: "",
    giftingTolandmark: "",
    postalCode: "",
    city: "",
    state: "",
    isValidForm: false,
  });
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState({
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal } = state;
  const [errorMM, setErrorMM] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const onsubmit = (e) => {
    e.preventDefault();
    if (values.isValidForm) {
      let mobile = !isNaN(values.mobile) ? values.mobile : "";
      let name = isNaN(values.name) ? values.name : "";
      if (mobile !== "" && values.mobile.length === 10 && name !== "") {
        var payload = {
          name: values.name,
          email: values.email,
          phone: values.mobile,
          selected_biryani: values.franchise_owner,
          quantity: values.quantity,
          country: values.country,
          gifting_to_name: values.giftingToname,
          message: values.message,
          gifting_to_fullname: values.giftingTofullname,
          gifting_to_mobile: values.giftingTomobile,
          gifting_to_email: values.giftingToemail,
          gifting_to_address: values.giftingToaddress,
          gifting_to_landmark: values.giftingTolandmark,
          postal_code: values.postalCode,
          city: values.city,
          state: values.state,
        };
        _saveGiftingOrder(payload)
          .then((res) => {
            if (res.result.status) {
              setIsSubmit(true);
              setValues({
                errorMessages: {},
                email: "",
                name: "",
                mobile: "",
                country: "",
                franchise_owner: "Veg",
                quantity: "",
                giftingToname: "",
                message: "",
                giftingTofullname: "",
                giftingTomobile: "",
                giftingToemail: "",
                giftingToaddress: "",
                giftingTolandmark: "",
                postalCode: "",
                city: "",
                state: "",
                isValidForm: false,
              });
            } else {
              handleClick();
              setErrorMM(res.result.message);
            }
          })
          .catch((err) => console.log(err));
      } else {
        for (var prop in values) {
          var event = {
            target: {
              value: values[prop],
              name: prop,
            },
          };
          validateFormFields(event, setValues, values);
        }
      }
    } else {
      for (var prop in values) {
        var event = {
          target: {
            value: values[prop],
            name: prop,
          },
        };
        validateFormFields(event, setValues, values);
      }
    }
  };
  React.useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
    });
  }, []);

  return (
    <div className="franchise-screen">
      <Helmet>
        <meta
          content="New year Special Offer | Love with Biryani"
          property="og:title"
        />
        <meta
          name="description"
          content="Best Biryani in Hyderabad, Chicken, Mutton, Veg, Egg Biryani online in Hyderabad"
        />
        <meta
          name="keywords"
          content="Love with Biryani menu, Love with Biryani "
        />
        <link rel="canonical" href={window.location.href} />
        <title>New year Special Offer | Love with Biryani</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <Header />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {errorMM}
        </Alert>
      </Snackbar>
      <section class="franchise-sec1 extra">
        <div className="cotainer">
          <div className="row">
            <div className="col-md-6 m-auto">
            <div className="clsdh">
            <div class="row">
              <div class="col-md-12">
                <div className="map-card">
                  <div className="map-img">
                    <div className="love-head">
                      Don’t let distance <br /> divide your l
                      <span>&#10084;</span>ve
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="clsdh mt-3">
            <Accordion
              expanded={expanded === "panel1"}
              // expanded
              onChange={handleChange("panel1")}
              className="custom-acc-pol"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                className="cust-summmary"
              >
                <Typography>
                  <img src={RoundedBall} alt="" width={15} />
                  &nbsp;Your Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="card-box">
                  <div class="franchise-form-div p-0">
                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                      className="w-100"
                    >
                      <div className="mt-2">
                        <TextField
                          label="Name *"
                          variant="standard"
                          className="w-100"
                          name="name"
                          onChange={(e) =>
                            validateFormFields(e, setValues, values)
                          }
                          value={values.name}
                        />
                        <div className="error-msg">
                          {values.errorMessages.name}
                        </div>
                      </div>
                      <div className="mt-4">
                        <TextField
                          label="Mobile Number*"
                          variant="standard"
                          className="w-100"
                          name="mobile"
                          onChange={(e) =>
                            validateFormFields(e, setValues, values)
                          }
                          value={values.mobile}
                        />
                        <div className="error-msg">
                          {values.errorMessages.mobile}
                        </div>
                      </div>
                      <div className="mt-4">
                        <TextField
                          label="Email Address*"
                          variant="standard"
                          className="w-100"
                          name="email"
                          onChange={(e) =>
                            validateFormFields(e, setValues, values)
                          }
                          value={values.email}
                        />
                        <div className="error-msg">
                          {values.errorMessages.email}
                        </div>
                      </div>
                      <div className="mt-4">
                        <TextField
                          label="Country*"
                          variant="standard"
                          className="w-100"
                          name="country"
                          onChange={(e) =>
                            validateFormFields(e, setValues, values)
                          }
                          value={values.country}
                        />
                        <div className="error-msg">
                          {values.errorMessages.country}
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              // expanded
              onChange={handleChange("panel2")}
              className="custom-acc-pol"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                className="cust-summmary"
              >
                <Typography>
                  <img src={RoundedBall} alt="" width={15} />
                  &nbsp;Gift Detail
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  className="w-100"
                >
                  <div className="mt-1">
                    <FormControl component="fieldset">
                      <label component="legend" className="lable-cv">
                        Select Premium Biryani Gift packs
                      </label>
                      <RadioGroup
                        row
                        aria-label="gender"
                        name="franchise_owner"
                        value={values.franchise_owner}
                        onChange={(e) => {
                          setValues({
                            ...values,
                            [e.target.name]: e.target.value,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="Veg"
                          control={<Radio />}
                          label="Veg"
                        />
                        <FormControlLabel
                          value="Chicken"
                          control={<Radio />}
                          label="Chicken"
                        />
                        <FormControlLabel
                          value="Fish"
                          control={<Radio />}
                          label="Fish"
                        />
                        <FormControlLabel
                          value="Prawn"
                          control={<Radio />}
                          label="Prawn"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="mt-1 extra-pol mb-2">
                    <TextField
                      label="Qty in number *"
                      variant="standard"
                      className="w-100"
                      name="quantity"
                      onChange={(e) => validateFormFields(e, setValues, values)}
                      value={values.quantity}
                    />
                    <div className="error-msg">
                      {values.errorMessages.quantity}
                    </div>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              // expanded
              onChange={handleChange("panel3")}
              className="custom-acc-pol"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                className="cust-summmary"
              >
                <Typography>
                  <img src={RoundedBall} alt="" width={15} />
                  &nbsp;Gifting To
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="card-box">
                  <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    className="w-100"
                  >
                    <div className="mt-2">
                      <TextField
                        label="Name *"
                        variant="standard"
                        className="w-100"
                        name="giftingToname"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.giftingToname}
                      />
                      <div className="error-msg">
                        {values.errorMessages.giftingToname}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Add a Special Message</label>
                      <div className="autocomplete-box">
                        <TextareaAutosize
                          minRows={3}
                          name="message"
                          onChange={(e) =>
                            validateFormFields(e, setValues, values)
                          }
                          value={values.message}
                        />
                        <div className="error-msg">
                          {values.errorMessages.message}
                        </div>
                        <label className="text-right w-100">
                          {values.message.length}/250 characters
                        </label>
                      </div>
                    </div>
                    <div className="head-polk">Delvery Information</div>
                    <div className="mt-2">
                      <TextField
                        label="Full Name *"
                        variant="standard"
                        className="w-100"
                        name="giftingTofullname"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.giftingTofullname}
                      />
                      <div className="error-msg">
                        {values.errorMessages.giftingTofullname}
                      </div>
                    </div>
                    <div className="mt-2">
                      <TextField
                        label="Mobile Number *"
                        variant="standard"
                        className="w-100"
                        name="giftingTomobile"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.giftingTomobile}
                      />
                      <div className="error-msg">
                        {values.errorMessages.giftingTomobile}
                      </div>
                    </div>
                    <div className="mt-2">
                      <TextField
                        label="Email *"
                        variant="standard"
                        className="w-100"
                        name="giftingToemail"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.giftingToemail}
                      />
                      <div className="error-msg">
                        {values.errorMessages.giftingToemail}
                      </div>
                    </div>
                    <div className="mt-2">
                      <label>Address</label>
                      <div className="autocomplete-box">
                        <TextareaAutosize
                          variant="standard"
                          className="w-100"
                          name="giftingToaddress"
                          onChange={(e) =>
                            validateFormFields(e, setValues, values)
                          }
                          value={values.giftingToaddress}
                        />
                        <div className="error-msg">
                          {values.errorMessages.giftingToaddress}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <TextField
                        label="Landmark *"
                        variant="standard"
                        className="w-100"
                        name="giftingTolandmark"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.giftingTolandmark}
                      />
                      <div className="error-msg">
                        {values.errorMessages.giftingTolandmark}
                      </div>
                    </div>
                    <div className="mt-2">
                      <TextField
                        label="Postal Code *"
                        variant="standard"
                        className="w-100"
                        name="postalCode"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.postalCode}
                      />
                      <div className="error-msg">
                        {values.errorMessages.postalCode}
                      </div>
                    </div>
                    <div className="mt-2">
                      <TextField
                        label="City *"
                        variant="standard"
                        className="w-100"
                        name="city"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.city}
                      />
                      <div className="error-msg">
                        {values.errorMessages.city}
                      </div>
                    </div>
                    <div className="mt-2 mb-2">
                      <TextField
                        label="State *"
                        variant="standard"
                        className="w-100"
                        name="state"
                        onChange={(e) =>
                          validateFormFields(e, setValues, values)
                        }
                        value={values.state}
                      />
                      <div className="error-msg">
                        {values.errorMessages.state}
                      </div>
                    </div>
                  </Box>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="text-center mt-4 mb-3 pb-4">
            <button
              className={"order-now"}
              type="submit"
              onClick={onsubmit}
            >
              Gift Now
            </button>
          </div>
            </div>
          </div>
        </div>
      </section>
      <ScussPopup
        isSubmit={isSubmit}
        setIsSubmit={setIsSubmit}
        hideOld={true}
      />
      <Footer />
    </div>
  );
}

export default Franchise;
