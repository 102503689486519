import React from "react";
import AOS from "aos";
import $ from "jquery";
import Header from "../components/layout/header/Header";
import { isMobile } from "react-device-detect";
import Profile from "../images/profile.png";
import PrProfile from "../images/pr-animation.svg";
import ExtraImg from "../images/img-pr1.svg";
import ExtraImg1 from "../images/cloud-oi.svg";
import Charminor from "../images/charminar.svg";
import Biryaniwala from "../images/biryaniwala.svg";
import LoveWithBiryani from "../images/sathabdhi.svg";
import Footer from "../components/layout/footer/Footer";
import { Helmet } from "react-helmet";
import Frame from "../images/Frame.svg";

function Aboutus() {
  React.useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    $(document).on("scroll", function () {
      if ($(document).scrollTop() > 50) {
        $("header").addClass("shrink");
        $(".lwblogo").addClass("shrinklogo");
        if ($(document).scrollTop() > 5199) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      } else {
        $("header").removeClass("shrink");
        $(".lwblogo").removeClass("shrinklogo");
      }
      if (isMobile) {
        if ($(document).scrollTop() > 2340) {
          $("#hide-and-show-img").addClass("hide-and-show-img");
        } else {
          if ($(document).scrollTop() < 2175) {
            $("#mobilecheck").addClass("hideimgpo");
          } else {
            $("#mobilecheck").removeClass("hideimgpo");
          }
          $("#hide-and-show-img").removeClass("hide-and-show-img");
        }
      }
    });
    (function () {
      window.addEventListener("scroll", function (event) {
        var depth, i, layer, layers, len, movement, topDistance, translate3d;
        topDistance = this.pageYOffset;
        layers = document.querySelectorAll("[data-type='parallax']");
        for (i = 0, len = layers.length; i < len; i++) {
          layer = layers[i];
          depth = layer.getAttribute("data-depth");
          movement = -(topDistance * depth);
          translate3d = "translate3d(0, " + movement + "px, 0)";
          layer.style["-webkit-transform"] = translate3d;
          layer.style["-moz-transform"] = translate3d;
          layer.style["-ms-transform"] = translate3d;
          layer.style["-o-transform"] = translate3d;
          layer.style.transform = translate3d;
        }
      });
    }.call(this));
  }, []);
  return (
    <div className="menu-screen">
      <Helmet>
        <meta content="About us | Love with Biryani" property="og:title" />
        <meta
          name="description"
          content="Love with Biryani introduces to you your favourite dish with a delicious twist"
        />
        <meta
          name="keywords"
          content="Love with Biryani, About love with Biryani"
        />
        <link rel="canonical" href={window.location.href} />
        <title>About us | Love with Biryani</title>
        <script
          dangerouslySetInnerHTML={{
            __html: `
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != "dataLayer" ? "&l=" + l : "";
            j.async = true;
            j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, "script", "dataLayer", "GTM-NQZ3WNN");
          `,
          }}
        ></script>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-10821370643"
        ></script>
        <script
          dangerouslySetInnerHTML={{
            __html: `
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "AW-10821370643");
      `,
          }}
        ></script>
      </Helmet>
      <Header />
      <div className="menu-top-sec bg-ijk">
        <p className="it1-pa it1-p4">About Us</p>
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto text-center">
              <div className="aboutus-p">
                A celebration of indulgence, love and nothing but the best. Love
                with Biryani introduces to you your favourite dish with a
                delicious twist. Redefining the legacy of Hyderabad and
                Hyderabadi’s favourite dish, Love With Biryani is here to ensure
                your celebrations get the love they deserve!
              </div>
              <div className="sp-header">The Man behind Love with biryani</div>
            </div>
          </div>
          <div className="about-us-card">
            <div className="row">
              <div className="col-md-8 m-auto about-us-card-sm">
                <div className="extrimg1">
                  <img
                    src={ExtraImg}
                    className="biryanimain-img"
                    alt="love with biryani"
                  />
                </div>
                <div className="extract-img">
                  <img
                    src={PrProfile}
                    className="biryanimain-img"
                    alt="love with biryani"
                  />
                </div>
                <div className="extrimg2">
                  <img
                    src={ExtraImg}
                    className="biryanimain-img"
                    alt="love with biryani"
                  />
                </div>
                <div className="frame">
                  <img
                    src={Frame}
                    className="biryanimain-img"
                    alt="love with biryani"
                  />
                </div>
                <div className="text-center mar-minus">
                  <img
                    src={Profile}
                    className="biryanimain-img"
                    alt="love with biryani"
                  />
                  <div className="pr-name-s">Siva Rapaka</div>
                  <p className="founder-p">Founder</p>
                  <div className="founder-content mt-1">
                    Mr Siva, a certified foodie and a flavourful businessman
                    takes his love for both and combines it into a success
                    story. Siva started his prolific career as an IT employee in
                    UK but the passion for food brewed in him since his
                    childhood. When asked by his parents of what he wanted to be
                    when he grows up, Siva, nonchalantly said - A FOOD CRITIC!
                  </div>
                  <div className="extrimg12">
                    <img
                      src={ExtraImg1}
                      className="biryanimain-img"
                      alt="love with biryani"
                    />
                  </div>
                  <div className="founder-content">
                    It was only during a camping trip in Canada with his friends
                    that when he made his legendary Biryani was when everyone
                    got a taste of what Siva had up his sleeves. After coaxing,
                    his iconic brand — Charminar Indian Cuisine was born.
                  </div>
                  <div className="founder-content">
                    His empire took traditional approach US and Canada followed
                    and disrupted it; creating a curated menu that is less
                    laborious and more formula based. His kitchen became a
                    chemistry lab and food became a science experiment. Months
                    of research paid off where his legacy is preceded by
                    authenticity and Indian cuisine.
                  </div>
                  <div className="founder-content">
                    Siva prides in providing the best of quality, taste and
                    consistency. A customer only gives you 2 chances, he says.
                    Their kitchen — that runs on the level of a cruise ship — is
                    the heart and soul of their business.
                  </div>
                  <div className="founder-content">
                    What’s more? His determination and passion has enabled his
                    business to form an alliance with Amazon Canada to provide
                    3000 weekly meals and also plans to leave a mark on the UK
                    too.
                  </div>
                  <div className="founder-content">
                    “My journey from Computer and Keyboard to Ladle and Pan will
                    always be driven by passion, hard work and a lot of
                    determination”, he says.
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-8 m-auto bottom-triangle"></div>
            </div>
          </div>
          <div className="our-brands">Our Other Brands</div>
          <div className="other-brands">
            <div className="row">
              <div className="col-md-6 m-auto">
                <div className="row">
                  <div className="col text-center">
                    <a
                      href="https://charminarindiancuisine.com/"
                      target="_blank"
                    >
                      <img
                        src={Charminor}
                        className="biryanimain-img max-wi"
                        alt="love with biryani"
                      />
                    </a>
                  </div>
                  <div className="col text-center">
                    <a href="https://thebiryaniwalla.com/" target="_blank">
                      <img
                        src={Biryaniwala}
                        className="biryanimain-img max-wi"
                        alt="love with biryani"
                      />
                    </a>
                  </div>
                  <div className="col text-center">
                    <a href="#">
                      <img
                        src={LoveWithBiryani}
                        className="biryanimain-img max-wi"
                        alt="love with biryani"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="last-abouts"></div> */}
      <Footer />
    </div>
  );
}

export default Aboutus;
