import React from "react";
import "./ampm.css";
import male from "../../images/male.svg";
import female from "../../images/female.svg";

export default function AmPm() {
  return (
    <section id="item-4" className="section4">
      <div className="bg-img-dgf">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2 className="it4-h2">
                <span className="it4-h2sp1">&nbsp;&nbsp;Whether</span>&nbsp;
                <span className="it4-h2sp2">AM or PM,</span>
              </h2>
              <h3 className="it4-h3">
                <span className="it4-h2sp1">&nbsp;Biryani cravings do</span>
                <span className="it4-h2sp2">n’t have time limits!</span>
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="desktop">
                <img
                  src={male}
                  alt="love with biryani"
                  className="gender-img"
                  data-aos="fade-right"
                  data-aos-offset="600"
                  data-aos-easing="ease-in-sine"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-anchor-placement="top-bottom"
                />
              </div>
              <div className="mobile">
                <img
                  src={male}
                  alt="love with biryani"
                  className="gender-img"
                />
              </div>
            </div>
            <div className="col">
              <div className="desktop">
                <img
                  src={female}
                  alt="love with biryani"
                  className="gender-img"
                  data-aos="fade-left"
                  data-aos-offset="600"
                  data-aos-easing="ease-in-sine"
                  data-aos-mirror="true"
                  data-aos-once="false"
                  data-aos-anchor-placement="top-bottom"
                />
              </div>
              <div className="mobile">
                <img
                  src={female}
                  alt="love with biryani"
                  className="gender-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
