import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function Datepicker({ values, setValues }) {
  return (
    <div>
      <DatePicker
        selected={values.store_open_date}
        onChange={(date) =>
          setValues({
            ...values,
            store_open_date: date,
          })
        }
        className="w-100 datepicker-cst"
        showTimeSelect={true}
        dateFormat="MMMM d, yyyy h:mm aa"
      />
    </div>
  );
}
