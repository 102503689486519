import React from "react";
import './mapseco.css';
import map from '../../images/map.jpg';

export default function MapsEco() {
  return (
    <section id="item-3" className="section3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="it3-h1">From Banjara Hills to Beverly Hills</h1>
            <h3 className="it3-h3 desktop">everyone loves Biryani!</h3>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p
              className="it3-imgp"
              data-aos="fade-in"
              data-aos-easing="ease-in-back"
              data-aos-delay="500"
              data-aos-offset="0"
              data-aos-mirror="true"
              data-aos-once="false"
              data-aos-anchor-placement="top-bottom"
            >
              <img
                src={map}
                className="it3-img"
                alt="love with biryani"
              />
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
