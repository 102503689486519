import React from "react";
import lwblogo from "../../../images/lwblogo.png";
import offer from "../../../images/offer.png";
import biryaniMin from "../../../images/biryani-min.png";
import HambergerMenu from "../../drawer-menu/DrawerMenu";
import "./header.css";
import { Link } from "react-router-dom";

export default function Header({ hideOrder }) {
  return (
    <div>
      <header>
        <div className="container-fluid">
          <div className="desktop">
            <div className="row">
              <div className="col">
                <Link to="/">
                  <img
                    src={lwblogo}
                    className="lwblogo"
                    alt="love with biryani"
                  />
                </Link>
              </div>
              <div className="col">
                <div className="d-flex img-hf">
                  <a
                    href="tel:+91 79810 20407"
                    className="link-ph"
                    target="_blank"
                  >
                    +91 79810 20407
                  </a>
                  <a
                    href="https://lovewithbiryanioow.petpooja.com/"
                    target="_blank"
                    className="link-or"
                  >
                    ORDER ONLINE
                  </a>
                  <HambergerMenu />
                </div>
              </div>
            </div>
          </div>
          <div className="mobile">
            <div className="row">
              <div className="col pt-2">
                <HambergerMenu />
              </div>
              <div className="col text-center">
                <Link to="/">
                  <img
                    src={lwblogo}
                    className="lwblogo"
                    alt="love with biryani"
                  />
                </Link>
              </div>
              <div className="col cts-right pt-2 pl-0">
                {!hideOrder && (
                  <a
                    href="https://lovewithbiryanioow.petpooja.com/"
                    target="_blank"
                    className="link-or"
                  >
                    ORDER ONLINE
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <div className="offer-imgdiv">
        <Link to="/newyear-offer">
          <img src={offer} className="offer-img" alt="love with biryani1" />
        </Link>
      </div> */}
      <div
        className="biryani-imgdiv"
        data-aos="fade-in"
        data-aos-offset="200"
        data-aos-delay="2000"
        data-aos-duration="300"
        data-aos-easing="zoom-out"
        data-aos-mirror="true"
        data-aos-once="true"
        data-aos-anchor-placement="top-bottom"
        id="hide-and-show-img"
      >
        <img
          src={biryaniMin}
          className="biryanimain-img"
          alt="love with biryani"
        />
      </div>
    </div>
  );
}
