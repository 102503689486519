export const validatePaswords = (data) => {
  try {
    let decimal =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,}$/;
    if (data.trim() == "") return "Please enter password";
    if (!data.match(decimal))
      return "Password must be atleast 5 or more characters with a mix of letters, numbers & special characters.";
  } catch (error) {
    console.log(error);
  }
};

export const validateMobile = (value) => {
  if (value.length < 10) {
    if (value == "") {
      return {
        error: "Enter valid 10 digit number",
        isValidForm: false,
      };
    } else {
      return {
        error: "Phone number must not be greater than 10 digits",
        isValidForm: false,
      };
    }
  } else if (value < 6000000000) {
    return {
      error: "Enter valid number",
      isValidForm: false,
    };
  } else if (value.length > 10) {
    return {
      error: "Phone number must be equal to 10 digits",
      isValidForm: false,
    };
  } else {
    return {
      error: "",
    };
  }
};

export const validateQty = (value) => {
  if (value < 10) {
    if (value == "") {
      return {
        error: "Enter your quantity",
        isValidForm: false,
      };
    } else {
      return {
        error:
          "For orders less than 10 please place your order through Order Online",
        isValidForm: false,
      };
    }
  } else {
    return {
      error: "",
    };
  }
};

export const validateFormFields = (event, setValues, values, other) => {
  let validEmailRegex = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i;
  let errors = values.errorMessages;
  let updatedValues = values;
  const validatemobileOrEmail = (value, name) => {
    if (value < 1) {
      errors[name] = "Enter email or mobile number";
      updatedValues.isValidForm = false;
      return false;
    } else if (!isNaN(value)) {
      const validateMob = validateMobile(value);
      errors[name] = validateMob.error;
      updatedValues.isValidForm = false;
      return false;
    } else if (isNaN(value)) {
      errors[name] = validEmailRegex.test(value) ? "" : "Enter valid email";
      updatedValues.isValidForm = false;
      return false;
    } else {
      return true;
    }
  };
  const isValid = (error) => {
    !error
      ? (updatedValues.isValidForm = true)
      : (updatedValues.isValidForm = false);
  };
  switch (event.target.name) {
    case "name":
      errors.name = event.target.value.length < 1 ? "Enter your Name" : "";
      updatedValues.name = event.target.value;
      isValid(errors.name);
      break;
    case "location":
      errors.location =
        event.target.value.length < 1 ? "Enter your Location" : "";
      updatedValues.location = event.target.value;
      isValid(errors.location);
      break;
    case "mobile":
      let isnum = /^\d+$/.test(event.target.value);
      if (isnum) {
        if (event.target.value.length > 10) {
        } else {
          const validateMob = validateMobile(event.target.value);
          errors.mobile = validateMob.error;
          updatedValues.mobile = event.target.value;
        }
      }
      if (event.target.value == "") {
        const validateMob = validateMobile(event.target.value);
        errors.mobile = validateMob.error;
        updatedValues.mobile = event.target.value;
      }
      isValid(errors.mobile);
      break;
    case "email":
      errors.email = validEmailRegex.test(event.target.value)
        ? ""
        : "Enter valid email";
      updatedValues.email = event.target.value;
      isValid(errors.email);
      break;
    case "password":
      const validatePass = validatePaswords(event.target.value);
      errors.password = validatePass;
      updatedValues.password = event.target.value;
      updatedValues.isValidForm = event.target.value < 1 ? false : true;
      break;
    case "confirmPassword":
      errors.confirmPassword =
        event.target.value != updatedValues.password
          ? "Password should be matched"
          : "";
      updatedValues.confirmPassword = event.target.value;
      isValid(errors.confirmPassword);
      break;
    case "mobileOrEmail":
      validatemobileOrEmail(event.target.value, "mobileOrEmail");
      !errors.mobileOrEmail
        ? (updatedValues.isValidForm = true)
        : (updatedValues.isValidForm = false);
      updatedValues.mobileOrEmail = event.target.value;
      isValid(errors.mobileOrEmail);
      break;
    case "mobileOrEmailOTP":
      validatemobileOrEmail(event.target.value, "mobileOrEmailOTP");
      updatedValues.mobileOrEmailOTP = event.target.value;
      isValid(errors.mobileOrEmailOTP);
      break;
    case "monthly_salary_income":
      errors.monthly_salary_income =
        event.target.value.length < 1 ? "Enter your monthly salary income" : "";
      updatedValues.monthly_salary_income = event.target.value;
      isValid(errors.monthly_salary_income);
      break;
    case "management_experience":
      errors.management_experience =
        event.target.value.length < 1 ? "Enter your management experience" : "";
      updatedValues.management_experience = event.target.value;
      isValid(errors.management_experience);
      break;
    case "franchise_it":
      errors.franchise_it =
        event.target.value.length < 1 ? "Enter your franchise it" : "";
      updatedValues.franchise_it = event.target.value;
      isValid(errors.franchise_it);
      break;
    case "quantity":
      let isnum1 = /^\d+$/.test(event.target.value);
      if (isnum1) {
        const validateMob = validateQty(event.target.value);
        errors.quantity = validateMob.error;
        updatedValues.quantity = event.target.value;
      }
      if (event.target.value === "") {
        const validateMob = validateQty(event.target.value);
        errors.quantity = validateMob.error;
        updatedValues.quantity = event.target.value;
      }
      isValid(errors.quantity);
      break;
    case "giftingToname":
      errors.giftingToname =
        event.target.value.length < 1 ? "Enter your Gifting Name" : "";
      updatedValues.giftingToname = event.target.value;
      isValid(errors.giftingToname);
      break;
    case "message":
      if (event.target.value.length > 250) {
        errors.message = "Message should be less than 250 Characters";
      } else {
        errors.message =
          event.target.value.length < 1 ? "Enter your message" : "";
        updatedValues.message = event.target.value;
      }
      isValid(errors.message);
      break;
    case "giftingTofullname":
      errors.giftingTofullname =
        event.target.value.length < 1 ? "Enter your Full Name" : "";
      updatedValues.giftingTofullname = event.target.value;
      isValid(errors.giftingTofullname);
      break;
    case "giftingTomobile":
      let isnum3 = /^\d+$/.test(event.target.value);
      if (isnum3) {
        if (event.target.value.length > 10) {
        } else {
          const validateMob = validateMobile(event.target.value);
          errors.giftingTomobile = validateMob.error;
          updatedValues.giftingTomobile = event.target.value;
        }
      }
      if (event.target.value == "") {
        const validateMob = validateMobile(event.target.value);
        errors.giftingTomobile = validateMob.error;
        updatedValues.giftingTomobile = event.target.value;
      }
      isValid(errors.giftingTomobile);
      break;
    case "giftingToemail":
      errors.giftingToemail = validEmailRegex.test(event.target.value)
        ? ""
        : "Enter valid email";
      updatedValues.giftingToemail = event.target.value;
      isValid(errors.giftingToemail);
      break;
    case "giftingToaddress":
      errors.giftingToaddress =
        event.target.value.length < 1 ? "Enter your address" : "";
      updatedValues.giftingToaddress = event.target.value;
      isValid(errors.giftingToaddress);
      break;
    case "giftingTolandmark":
      errors.giftingTolandmark =
        event.target.value.length < 1 ? "Enter your landmark" : "";
      updatedValues.giftingTolandmark = event.target.value;
      isValid(errors.giftingTolandmark);
      break;
    case "postalCode":
      errors.postalCode =
        event.target.value.length < 1 ? "Enter your postal code" : "";
      updatedValues.postalCode = event.target.value;
      isValid(errors.postalCode);
      break;
    case "city":
      errors.city = event.target.value.length < 1 ? "Enter your city" : "";
      updatedValues.city = event.target.value;
      isValid(errors.city);
      break;
    case "state":
      errors.state = event.target.value.length < 1 ? "Enter your state" : "";
      updatedValues.state = event.target.value;
      isValid(errors.state);
      break;
    case "country":
      errors.country = event.target.value.length < 1 ? "Enter your country" : "";
      updatedValues.country = event.target.value;
      isValid(errors.country);
      break;
    case "other":
      errors[other.value] =
        event.target.value.length < 1 ? `Enter Your ${other.name}` : "";
      updatedValues[other.value] = event.target.value;
      updatedValues.isValidForm = event.target.value < 1 ? false : true;
      break;
    default:
      break;
  }
  setValues({
    ...values,
    updatedValues,
    errorMessages: errors,
  });
};
